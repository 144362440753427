import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { Redirect } from 'react-router-dom'
import {format} from "date-fns";
import { CLOUDFRONT_URL, IMAGE_THUMB_URL, PAGE10, PORT } from '../../../config/config'
// IMAGE_FULL_URL,
import CarLeasingView from '../CarLeasingView'
import * as moment from 'moment'
import 'moment/locale/th'
import {LazyLoadImage} from "react-lazy-load-image-component";
import CarNote from "../CarNote";
import {checkGroupPermission} from "../../../services/grouppermission";
import {getProfitBeforeSell, getSumCostBeforeSellNoRepair} from "../../../services/costProfitService";

export default class CarRowReservation extends Component {
  constructor (props) {
    super(props)
    this.state = {
      'btnClick': '',
      'carSelect': 0,
      car_id: '',
      upload_code: '',
      carcalculate: false,
      carLeasingList: [],
      cars: [],
      form_status: '',
      claim_status: '',
    }
    this.toggleModal= this.toggleModal.bind(this);
    this.openClaimAdd= this.openClaimAdd.bind(this);
    this.getCarBookManageButton= this.getCarBookManageButton.bind(this);
  }

  toQueryString (paramsObject) {
    return Object
      .keys(paramsObject)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
      .join('&')

  }

  handleOnClick (action, car, car_sell) {

    var search3 = this.toQueryString(car)

    this.setState({
      'btnClick': action,
      'carSelect': search3,
      'car_id': car.car_id,
      'upload_code': car.car_upload_code,
    })

    if (action === 'car-edit-status') {
      this.props.onCarStatusEdit(car)
    } else if (action === 'car-calculate') {
      this.props.onCarCalculate(car)
    } else if (action === 'car-share') {
      //alert(car.car_share)
      this.props.onCarShare(car)
    } else if (action === 'car-delete') {
      this.props.onCarDelete(car)
    } else if (action === 'car-leasing'){
      this.props.onCarLeasing(car)
    } else if (action === 'car-release') {
      this.props.onCarRelease(car)
    }

  }

  componentDidMount() {
    this.setState({
      cars: this.props.cars
    });
  }

  componentWillReceiveProps(nextProps) {

    if(nextProps.carLeasingList.length ===0 && this.props.carLeasingList.length === 0 ) {
      this.setState({carLeasingList: []});
    }else if(nextProps.carLeasingList !== this.props.carLeasingList) { // && this.props.carLeasingList.length === 0
      if (nextProps.carLeasingList.length > 0 ) {
        this.setState({
          carLeasingList: nextProps.carLeasingList
        })
      }else{
        this.setState({carLeasingList: []});
      }
    }

    if(nextProps.cars !== this.props.cars) {
      this.setState({
        cars: nextProps.cars
      });
    }
  }

 /* shouldComponentUpdate(nextProps, nextState) {

    return this.props.carLeasingList !== nextProps.carLeasingList || nextState.input !== this.state.input
  } */

  openClaimAdd() {
    /* start : ออกใบเคลม
    process : กำลังดำเินการซ่อม
    success : ซ่อมเสร็จรอลูกค้ามารับ
    complete : ลูกค้ามารับเรียบร้อย
    this.setState({
      claim_status: 'start',
      form_status: 'add'
    },()=>{
      this.toggleModal();
    });*/

   // this.pro

  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  getCarBookManageButton(car) {
    let user_type = this.props.user_type
    let grouppermissions = this.props.grouppermissions

    if (PORT === 9043 && user_type ==='sale') {
      return <td className='text-center'>

        <button onClick={() => this.handleOnClick('car-detail', car)} type="button"
                className="btn btn-primary btn-sm btn-block"><i className="icon-list">
          &nbsp;รายละเอียดรถ</i>
        </button>

        <button onClick={() => this.handleOnClick('car-book', car)} type="button"
                className={this.props.user_type === 'technician' ? 'hidden' : 'btn btn-info btn-sm btn-block'}
        >
          <i className="icon-star"/>
          &nbsp;ดูการจอง/จองเพิ่ม
        </button>
      </td>
    }

    return  <td>
      {
        checkGroupPermission(18, grouppermissions).viewed ? (
            <button onClick={() => this.handleOnClick('car-detail', car)} type="button"
                    className="btn btn-info btn-sm btn-block"><i className="icon-list">
              &nbsp;รายละเอียดรถ</i>
            </button>
        ) : null
      }

      {
        checkGroupPermission(23, grouppermissions).created ? (
            <button onClick={() => this.handleOnClick('car-repair', car)} type="button"
                    className="btn btn-danger btn-sm btn-block"><i className="icon-wrench">
              &nbsp;รายการปรับแต่ง</i>
            </button>
        ) : null
      }

      {
        checkGroupPermission(101, grouppermissions).viewed ? (
            <button onClick={() => this.handleOnClick('car-edit-status', car)} type="button"
                    className={this.props.user_type === 'technician' ? 'hidden' : 'btn btn-warning btn-sm btn-block'}
            ><i className="icon-note">
              &nbsp;แก้ไขสถานะ</i>
            </button>
        ) : null
      }

      {
        checkGroupPermission(701, grouppermissions).viewed ? (
            <button onClick={() => this.handleOnClick('car-book', car)} type="button"
                    className={this.props.user_type === 'technician' ? 'hidden' : 'btn btn-info btn-sm btn-block'}
            >
              <i className="icon-star"/>
              &nbsp;ดูการจอง/จองเพิ่ม
            </button>
        ) : null
      }

      {/*<button  onClick={()=>this.handleOnClick('car-book-cancel', car)}  type="button" className={(car.car_status_id === 7)? "btn btn-danger btn-sm btn-block":"btn btn-danger btn-sm btn-block hidden"}><i className="icon-close"> จอง/ยกเลิกการจอง</i></button>*/}
      {
        checkGroupPermission(104, grouppermissions).viewed ? (
            <button onClick={() => this.handleOnClick('car-buy', car)}
                    type="button"
                    className={this.props.user_type === 'technician' ? 'hidden' : 'btn btn-success btn-sm btn-block'}
            >
              <i className="icon-trophy">
                &nbsp;ขายรถคันนี้</i>
            </button>
        ) : null
      }

      {
        checkGroupPermission(1, grouppermissions).deleted ? (
            <button onClick={() => this.handleOnClick('car-delete', car)}
                    type="button"
                    className={this.props.user_type === 'admin'? 'btn btn-danger btn-sm btn-block': 'hidden'}

            ><i className="icon-trash">
              &nbsp;ลบรถคันนี้</i>
            </button>
        ) : null
      }

      {
        checkGroupPermission(704, grouppermissions).created ? (
            <button
                type="button"
                onClick={() => this.handleOnClick('car-release', car)}
                className="btn btn-sm btn-primary btn-block mt-2"
            >
              <i className="icon-plus">&nbsp; ใบปล่อยรถ</i>
            </button>
        ) : null
      }

      {
        PORT === 9056 && checkGroupPermission(701, grouppermissions).modified ? (
            <button onClick={() => {
              // this.changeCarBook(car)
              let isModal = true
              this.props.onChangeCarBook(car, isModal)
            }}
                    type="button" className="btn btn-danger btn-sm btn-block"
                    style={{backgroundColor: '#F57B39'}}
            >
              <i className="icon-control-rewind">&nbsp; เปลี่ยนคัน </i>
            </button>
        ) : null
      }


    </td>
  }

  render () {

    let target = '';
    if (this.state.btnClick === 'car-repair') {
       target = '/car-repair/new?car_id=' + this.state.car_id
      return <Redirect push to={target}/>
    } else if (this.state.btnClick === 'car-detail') {
       target = '/cars/detail/?car_id=' + this.state.car_id
      target += '&upload_code=' + this.state.upload_code
      return <Redirect push to={target}/>
    } else if (this.state.btnClick === 'car-buy') {
       target = '/sales/buy/?car_id=' + this.state.car_id
      return <Redirect push to={target}/>
    } else if (this.state.btnClick === 'car-book') {
       target = '/cars/book/?car_id=' + this.state.car_id
      return <Redirect push to={target}/>

    } else if (this.state.btnClick === 'car-book-cancel') {
       target = '/cars/book-cancel/?car_id=' + this.state.car_id
      return <Redirect push to={target}/>
    }

    let width = this.props.width
    let username = this.props.username
    let page = this.props.page
    let endIndex = PAGE10*page
    let startIndex = (PAGE10*page - PAGE10) + 1

    let imgWidthCol = 360
    let imgWidth = ''
    if ( width >= 1340) {
      imgWidth = '300px'
      imgWidthCol = 320
    } else if (width >= 1024) {
      imgWidth = '165px'
      imgWidthCol = 180
    } else if (width >= 980) {
      imgWidth = '140px'
      imgWidthCol = 160
    } else {
      imgWidth = '120px'
      imgWidthCol = 140
    }

    let tbodyList = this.state.cars.map((car, index) => {
      // let rowCount = this.state.cars.length;
      // let currentRow = rowCount - index
      let currentRow = startIndex + index
      let image_url = ''
      if (car.car_upload_filename.includes('https')) {
        // image_url = car.car_upload_filename
        image_url = CLOUDFRONT_URL+car.car_upload_key
      } else {
        image_url = IMAGE_THUMB_URL + car.car_upload_filename
      }

      // format(new Date(car.customer_outcar_date), 'dd/MM/yyyy');
      let customer_outcar_date = car.customer_outcar_date === null ? '-' : moment(car.customer_outcar_date).locale('th').format('ll');
      let finance_approve_date = car.finance_approve_date === null ? '-' : moment(car.finance_approve_date).locale('th').format('ll');

      let car_cost_other_sum = getSumCostBeforeSellNoRepair(car)
      let profit = getProfitBeforeSell(car)
      let car_cost_total = car.car_cost + car.car_cost_repair + car_cost_other_sum + Number(car.vat_buy)

      return (
        <tr className="text-center" key={index}>
          <td>{currentRow}</td>
          <td>
            <div className="row">
              <div className="col-md-12 mt-2">

                <LazyLoadImage src={image_url}
                               width={imgWidth}
                               height="auto"
                               effect="blur"
                />

              </div>
            </div>
            <div className="row hidden-md ">
              <div className="col col-sm-12 col-md-12 col-lg-12 mt-2">
                <button type="button" onClick={() => this.handleOnClick('car-calculate', car)}
                        className="btn btn-success btn-sm btn-block ">
                  <i className="icon-calculator"/> ค่างวด
                </button>
              </div>
              <div className="col col-sm-12 col-md-12 col-lg-12 mt-2">
                <button type="button" onClick={() => this.handleOnClick('car-share', car)}
                        className="btn btn-primary btn-sm btn-block ">
                  <i className="icon-share"/> แชร์
                </button>
              </div>
            </div>

            {
              checkGroupPermission(705, this.props.grouppermissions).viewed ?
                  <div className="mt-3">
                      <CarNote
                          car_id={car.car_id}
                          customer_id={car.customer_id}
                          note_type={'reserve'}
                          username={username}
                      />
                  </div>
                  : null
            }

          </td>
          <td className="text-center">
            {
              car.car_book_id !== null || car.car_book_id !== undefined ?
                <div>
                  <strong>
                    <span className="text-success">
                      ผู้จอง #{car.car_book_id}: {car.customer_name} {' '} {car.customer_lastname} {'('+car.customer_mobile +')'}
                    </span>
                    <br/>
                    อาชีพ : {car.occupation_name === null ? '-' : car.occupation_name}
                    <br/>
                    โปรไฟล์ลูกค้า : {car.profile_name === '' ? '-' : car.profile_name}
                    <br/>
                    จองเมื่อ : {moment(car.booking_time).locale('th').format('LLL')}
                    <br/>
                    {
                      car.bookDays > 0 && <span
                          className={car.bookDays >=7 ? "badge blink mt-1" : "badge mt-1"}
                          style={{backgroundColor: '#f86c6b'}}
                      >
                        จองมาแล้ว {car.bookDays} วัน
                      </span>
                    }

                    <span
                        className="badge mt-1 ml-1"
                        style={{backgroundColor: '#F4CE14'}}
                    >
                       ไฟแนนท์อนุมัติ: {finance_approve_date}
                    </span>
                    <>
                    <br/>
                    { PORT === 9048 ? 'ผู้ขาย : ' + (car.employee_book_id === 0 || car.employee_book_id === '' ? 'ไม่ระบุ' : car.employee_book_name + ' ' + car.employee_book_lastname) : null}
                    </>
                  </strong>
                  <hr/>
                </div>
                : null
            }

            <strong>{car.car_name}</strong>
            <br/>
            {
              car.car_license_plate_new === '' ? null : (
                  <>
                    <strong> ทะเบียนรถ : {car.car_license_plate_new}</strong>
                    &nbsp; <strong>จ.{car.province_name_new}</strong>
                  </>
              )
            }

            {
              car.car_license_plate_new !== '' && car.car_license_plate_old !== '' && car.car_license_plate_new !== car.car_license_plate_old ? <br/> : null
            }

            {
              car.car_license_plate_new !== car.car_license_plate_old && car.car_license_plate_old !== '' ? (
                      <>
                        <strong> &nbsp;&nbsp; ทะเบียนเดิม : {car.car_license_plate_old} &nbsp;จ.{car.province_name_old}</strong>
                      </>
                  )
                  : null
            }
            {/*<strong> ทะเบียนรถ : {car.car_license_plate_new}</strong>*/}
            <br/>
            <img src={'img/if_world.png'} style={{width: 16}}
                 className={car.car_share === 'show' ? '' : 'hidden'}
                 alt="share status"
            />
            <br/>

            <span className="badge"
                  style={{backgroundColor: '#4DBD74'}}
            >
               <strong> วันที่ออกรถ : {customer_outcar_date} </strong>
            </span>

            <center>

              { PORT === 9048 ?
                  <>
                  <span className="badge mt-3"
                        style={{backgroundColor: car.car_status_color}}>{car.car_status_name}
                  </span>
                  <br/>
                  <br/>โดย : {car.booking_fullname}
                  <br/>วันที่ : {moment(car.booking_time).locale('th').format('LLL')}
                </>
                :
                  <CarLeasingView
                      car={car}
                      carLeasingList={this.state.carLeasingList}
                  />
              }

            {/*<button type="button"
                    style={{marginTop: 8}}
                    onClick={() => this.handleOnClick('car-leasing', car)}
                    className="btn btn-outline-danger btn-sm btn-block w-50">
              <i className="icon-diamond"></i> ยอดจัด
            </button>*/}

            </center>
          </td>
          {/*<td className={this.props.user_type === 'admin' ? 'text-center' : 'hidden'}>
            <NumberFormat
               value={car.car_cost}
               displayType={'text'}
               thousandSeparator={true}
               prefix={'฿'}
            />
          </td>*/}
          {/*<td>
          </td>*/}
          {/*<td className={this.props.user_type === 'admin' ? 'text-center' : 'hidden'}>
            <NumberFormat
               value={car.car_net_profit}
               displayType={'text'}
               thousandSeparator={true} prefix={'฿'}
            />
          </td>*/}
          <center>
          <td>
            <table>
              <tbody>

                <tr className={this.props.user_type === 'admin' ? 'text-left text-primary' : 'hidden'}>
                    <td className="text-left text-nowrap">ทุนซื้อเข้า :</td>
                    <td className="text-left">
                      <NumberFormat
                          value={car.car_cost}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'฿'}
                          decimalScale={2} fixedDecimalScale
                      />
                    </td>
                  </tr>

                <tr className={this.props.user_type === 'admin' ? 'text-left' : 'hidden'}>
                  <td className="text-left text-nowrap">ภาษีซื้อ (Vat ซื้อ) :</td>
                  <td className="text-left">
                    <NumberFormat
                        value={car.vat_buy}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'฿'}
                        decimalScale={2} fixedDecimalScale
                    />
                  </td>
                </tr>

                <tr className={this.props.user_type === 'admin' ? 'text-left' : 'hidden'}>
                  <td className="text-left text-nowrap">ทุนซ่อม/แต่ง :</td>
                  <td className="text-left">
                    <NumberFormat
                        value={car.car_cost_repair}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'฿'}
                        decimalScale={2} fixedDecimalScale
                    />
                  </td>
                </tr>

                <tr className={this.props.user_type === 'admin' ? 'text-left' : 'hidden'}>
                  <td className="text-left text-nowrap">ทุนอื่นๆ :</td>
                  <td className="text-left">
                    <NumberFormat
                        value={car_cost_other_sum}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'฿'}
                        decimalScale={2} fixedDecimalScale
                    />
                  </td>
                </tr>

                <tr className={this.props.user_type === 'admin' ? 'text-left text-primary' : 'hidden'}>
                  <td className="text-left text-nowrap">รวมทุน :</td>
                  <td className="text-left">
                    <NumberFormat
                        value={car_cost_total.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'฿'}
                        decimalScale={2} fixedDecimalScale
                    />
                  </td>
                </tr>

                <tr>
                  <td className="text-left text-nowrap">ราคาขาย :</td>
                  <td className="text-left">
                    <NumberFormat
                        value={car.car_sale_price}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'฿'}
                        decimalScale={2} fixedDecimalScale
                    />
                  </td>
                </tr>

                <tr className={this.props.user_type === 'admin' ? 'text-left text-success' : 'hidden'}>
                  <td className="text-left text-nowrap">กำไร :</td>
                  <td className="text-left">
                    <NumberFormat
                        value={profit.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'฿'}
                        decimalScale={2} fixedDecimalScale
                    />
                  </td>
                </tr>
              <br/>
              </tbody>
            </table>


            { PORT === 9048 ? null :
                <>
                <span className="badge"
                      style={{backgroundColor: car.car_status_color}}>{car.car_status_name}
                </span>
                <br/>
                <br/>โดย : {car.booking_fullname}
                <br/>วันที่ : {moment(car.booking_time).locale('th').format('LLL')}
              </>
            }
          </td>
          </center>

          {
            this.getCarBookManageButton(car)
          }
        </tr>
      )
    });

    let cars = this.state.cars
    if (cars.length === 0) {
      return (
          <tbody>
          <tr>
            <td colSpan={6} className="text-center text-danger">ไม่พบข้อมูล</td>
          </tr>
          </tbody>
      );
    } else {
      return (
          <tbody>
          {tbodyList}
          </tbody>
      );
    }
  }
}

