export const getSumCostBeforeSellNoRepair = (car) => {
    // ทุนรถก่อนขาย ที่ ไม่รวมราคารถ + vatรถ และไม่รวมค่าซ่อมรถ
    let cost_operation = car.cost_operation;
    let cost_service = car.cost_service;
    let cost_transport = car.cost_transport;
    let cost_porobor_lan = car.cost_porobor_lan;
    let cost_bangkhab_phasi_lan = car.cost_bangkhab_phasi_lan;
    let cost_bangkhab_xon_lan = car.cost_bangkhab_xon_lan;
    let cost_check_ton = car.cost_check_ton;
    let car_cost_commissions = car.car_cost_commissions
    let car_cost_act = car.car_cost_act
    let car_cost_tax = car.car_cost_tax

    let car_cost_expenses_other = car.car_cost_expenses_other + car.car_cost_other
    let car_cost_other_sum = car_cost_expenses_other

    // at
    car_cost_other_sum += Number(cost_operation);
    car_cost_other_sum += Number(cost_service);
    car_cost_other_sum += Number(cost_transport);
    car_cost_other_sum += Number(cost_porobor_lan);
    car_cost_other_sum += Number(cost_bangkhab_phasi_lan);
    car_cost_other_sum += Number(cost_bangkhab_xon_lan);
    car_cost_other_sum += Number(cost_check_ton);
    //
    car_cost_other_sum += Number(car_cost_commissions);
    car_cost_other_sum += Number(car_cost_act);
    car_cost_other_sum += Number(car_cost_tax);

    return car_cost_other_sum
}


export const getSumCostBeforeSell = (car) => {
    // ค่าใช้จ่ายซื้อรถเข้าและรวมค่าซ่อม ไม่รวมราคารถ+vatรถ
    let car_cost_repair = car.car_cost_repair;
    let car_cost_other_sum = getSumCostBeforeSellNoRepair(car)
    car_cost_other_sum += Number(car_cost_repair);
    return car_cost_other_sum
}

export const getProfitBeforeSell = (car) => {
    let car_cost_other_sum = getSumCostBeforeSellNoRepair(car)
    let profit = car.car_sale_price - (car.car_cost + car.vat_buy  + car_cost_other_sum + car.car_cost_repair)
    return profit
}

// ส่วนต่างเงินคืนลูกค้า => รายรับของเต็นท์
export const getRefundDifference = (car) => {
    const car_excess_amount = car.car_excess_amount
    const customer_cash = car.customer_cash
    let refund_difference = 0
    let refund_cash = 0
    if(car_excess_amount > 0){
        // เงินคืนลูกค้า
        //refund_type = 1 คืนเงินเต็ม
        //refund_type = 2 ไม่คืนเงิน
        //refund_type = 3 คืนเงินบางส่วน
        if(car.refund_type === 0){
            refund_cash = 0
        }else if(car.refund_type === 1){
            refund_cash = car.customer_cash
        }else if(car.refund_type === 2){
            refund_cash = 0
        }else if(car.refund_type === 3){
            refund_cash = car.refund_inpart
        }
        refund_difference = customer_cash - refund_cash
    }
    return {
        refund_difference, refund_cash
    }
}


//ส่วนการขาย
export const getSumCostAfterSell = (car) => {
    //ค่าใช้จ่ายการขาย ทางเต็นท์รับผิดชอบ
    let car_external_commission = car.car_external_commission;
    let sales_commission = car.sales_commission;
    let additional_commission = car.additional_commission;
    let advertising_cost = car.advertising_cost;
    let delivery_fee = car.delivery_fee;
    let finance_fee = car.finance_fee;
    let promotion_fee = car.promotion_fee;
    let transfer_fee = car.transfer_fee;
    let cost_after_sales_service = car.cost_after_sales_service;
    let cost_phra = car.cost_phra;
    let cost_vat_and_property_tax = car.cost_vat_and_property_tax;

    let car_cost_after_sell = Number(car_external_commission) + Number(sales_commission) + Number(additional_commission)
                            + Number(advertising_cost) + Number(delivery_fee) + Number(finance_fee) + + Number(promotion_fee)
                            + Number(transfer_fee) + Number(cost_after_sales_service) + Number(cost_phra) + Number(cost_vat_and_property_tax);
    return car_cost_after_sell

}

export const getSumCostFinanceAfterSell = (car_sell) => {
    // ค่าใช้จ่ายจากไฟแนนซ์ (บันทึกกรณีจ่ายแล้ว)
    let finance_pa = car_sell.finance_pa
    let finance_car_insurance = car_sell.finance_car_insurance
    let finance_ew = car_sell.finance_ew
    let finance_pay_advance = car_sell.finance_pay_advance
    let finance_health_insurance = car_sell.finance_health_insurance
    let finance_document_fee = car_sell.finance_document_fee
    let finance_transfer_fee = car_sell.finance_transfer_fee
    let finance_stamp_fee = car_sell.finance_stamp_fee

    return finance_pa+finance_car_insurance+finance_ew+finance_pay_advance+finance_health_insurance+finance_document_fee+finance_transfer_fee+finance_stamp_fee
}



export const getSumCostSellPayByCustomer = (car_sell) => {
    //รวมค่าใช้จ่ายตอนขาย ที่ลูกค้าต้องจ่ายเพิ่มเอง
    //เกิดขึ้นแค่กรณี ยอดจัดเกิน
    let car_excess_amount = car_sell.car_excess_amount
    let car_excess_amount_use = 0
    if(car_excess_amount > 0) {
        car_excess_amount_use =  car_sell.debt_insurance_amount + car_sell.cost_sell_act + car_sell.total_outof_discount

        if(car_sell.insurance_buy === 'ซื้อเอง'){
            // ราคาประกันรถยนต์
            if (car_sell.finance_car_insurance === 0) {
                car_excess_amount_use += car_sell.insurance_price
            }
        }

        if(car_sell.insurance_pa_buy === 'PA ซื้อเอง'){

            if (car_sell.finance_pa === 0) {
                car_excess_amount_use += car_sell.insurance_pa
            }
        }

        if (car_sell.finance_ew === 0) {
            car_excess_amount_use += car_sell.gearbox_amount
        }

        if (car_sell.finance_pay_advance === 0) {
            car_excess_amount_use += car_sell.pay_advance_finance
        }

        car_excess_amount_use += car_sell.cost_finance
        // if (car_excess_amount_use+car_sell.cost_finance <= car_excess_amount) {
        //}

    }

    return car_excess_amount_use

}

