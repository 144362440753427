import React, { Component } from 'react'
import Loading from '../Loading'
import { APIURL } from '../../config/config'
import axios from 'axios'
import { MonthUtil } from '../../utils/monthUtil'
import { th } from 'date-fns/locale'
import BDatePicker from '../BDatePicker'
// import { isNumber } from 'chart.js/helpers'
import { AlertError, AlertSuccess } from '../Alert/Alert'
import { DateUtil } from '../../utils/dateUtil'
import {format} from "date-fns";

class TrackInformationCarSell extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      is_first_load: true,
      month_year_list: [],
      car_sells: [],
      car_license_plate: '',
      outstanding_sparepart: '',
      liciense_status: [],
      sell_month_year: ''
    }
    this.loadCarSellMonthList = this.loadCarSellMonthList.bind(this)
    this.sellMonthYearChange = this.sellMonthYearChange.bind(this)
    this.loadCarSellData = this.loadCarSellData.bind(this)
    this.onChangeDate = this.onChangeDate.bind(this)
    this.onClickSave = this.onClickSave.bind(this)
    this.loadLicienseStatus = this.loadLicienseStatus.bind(this)
    this.exportExcelCarSell = this.exportExcelCarSell.bind(this)
  }

  componentDidMount () {
     this.loadCarSellMonthList()
     this.loadLicienseStatus()
  }

  loadLicienseStatus() {
    axios.get(APIURL + '/liciense_status/')
        .then(res => {
          this.setState({liciense_status: res.data})
        }).catch(error => {
      this.setState({liciense_status: []})
    })
  }

  loadCarSellMonthList() {
    let uri = APIURL + '/car_sell/list/month'
    axios.get(uri)
      .then(res => {
        if( res.data.length > 0) {
          let months = res.data
          let month_year_list =  months.map(month => {
            let month_list_thai = MonthUtil.convertMonthYear(month.month_list)
            return {
              ...month,
              month_list_thai: month_list_thai
            }
          });

          this.setState({
            month_year_list: month_year_list
          }, () => {
            if (this.state.is_first_load) {
              this.loadCarSellData('no_send_book')
            }
          })
        }
      }).catch(error => {

    })
  }

  sellMonthYearChange(e) {
    let value = e.target.value
    this.setState({
      sell_month_year: value,
      car_license_plate: '',
      outstanding_sparepart: ''
    }, () => {
      // this.loadCarSellData(value)
    })

  }

  loadCarSellData(data) {
    let typeLoad = ''
    let start_date = ''
    let end_date = ''

    if(data === null) {
      typeLoad ='no_send_book'
    } else if (data === 'no_send_book') {
      typeLoad ='no_send_book'
    } else {
      typeLoad = 'date'
    }

    let month_list_thai = ''
    if(typeLoad === 'no_send_book') {
      start_date = 'no_send_book'
      end_date = 'no_send_book'
      month_list_thai = 'no_send_book'
    } else {
      const month_year_list = this.state.month_year_list.find(month_year => month_year.month_list === data)
      start_date = month_year_list.start_date
      end_date = month_year_list.end_date
      month_list_thai = month_year_list.month_list_thai
    }

    let car_license_plate = this.state.car_license_plate
    let outstanding_sparepart = this.state.outstanding_sparepart

    let dataRequest = {
      start_date: start_date,
      end_date: end_date,
      car_license_plate: car_license_plate,
      outstanding_sparepart: outstanding_sparepart
    }

    // let uri = APIURL + '/car_sell/trackinformation/'+start_date+'/'+end_date
    let uri = APIURL + '/car_sell/trackinformation'
    axios.post(uri, dataRequest).then(res => {

      let data = res.data.map(car => {
        return {...car, car_edit: 1}
      })

      this.setState({
        car_sells: data,
        isLoading: false
      })
    }).catch(error => {
      console.log(error)
    })

  }

  onChangeDate(carSell, name, date) {
    let car_sell_id = carSell.car_sell_id
    let value = date === '1970-01-01' ? null : date

    let car_list = this.state.car_sells.map(car => car.car_sell_id === car_sell_id ? {...car, [name]: value, car_edit: 2 } : car )

    this.setState({
      car_sells: car_list
    });

  }

  onChangeInputRow(carSelect, index, event) {

    let car_sell_id = carSelect.car_sell_id
    let name = event.target.name
    let value = event.target.value

    let car_list = this.state.car_sells.map(car => car.car_sell_id === car_sell_id ? {...car, [name]: value, car_edit: 2 } : car )

    this.setState({
      car_sells: car_list
    });

  }

  onClickSave(car_sell, index,) {

      let data = {
        car_sell_id: car_sell.car_sell_id,
        receipt_transfer_document_date: car_sell.receipt_transfer_document_date,
        transfer_document_status_text: car_sell.transfer_document_status_text,
        transfer_document_send_date: car_sell.transfer_document_send_date,
        outstanding_sparepart: car_sell.outstanding_sparepart,
        book_status_text: car_sell.book_status_text,
        liciense_status_id: car_sell.liciense_status_id,
        car_id: car_sell.car_id,
      }


    this.setState({
      isLoading: true
    }, () => {
      let car_sell_id = car_sell.car_sell_id
      let url = APIURL+'/car_sell/trackinformation/'+car_sell_id
      axios.put(url, data).then(res => {

        if(res.status === 200) {
          let car_list = this.state.car_sells.map(car => car.car_sell_id === car_sell_id ? {...car, car_edit: 1,} : car )
          this.setState({
            car_sells: car_list,
            isLoading: false
          }, () => {
            AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
            this.loadCarSellData('no_send_book')
          });

        }
      })
        .catch(error => {
          AlertError("พบข้อผิดพลาด : " + error)
          this.setState({
            isLoading: false
          })
        })

    })
  }

  exportExcelCarSell() {
    let car_sells = this.state.car_sells

    this.setState({
      isLoading: true
    })

    let excelData = car_sells.map((car_sell, index) => {
      let order = index +1
      let car_sell_date = DateUtil.convertCCtoDDMMYYYY(car_sell.car_sell_date)
      let car_issue_date = car_sell.car_issue_date !== null ? DateUtil.convertCCtoDDMMYYYY(car_sell.car_issue_date) : '-'
      let receipt_transfer_document_date = car_sell.receipt_transfer_document_date !== null ? DateUtil.convertCCtoDDMMYYYY(car_sell.receipt_transfer_document_date) : '-'
      let transfer_document_send_date = car_sell.transfer_document_send_date !== null ? DateUtil.convertCCtoDDMMYYYY(car_sell.transfer_document_send_date) : '-'
      let license_plate_new = car_sell.car_license_plate_new+' : '+car_sell.province_name_new
      let license_plate_old = car_sell.car_license_plate_old+' : '+car_sell.province_name_old
      let customer_fullname = car_sell.customer_name+' '+car_sell.customer_lastname

      let row = {
        order: order,
        car_sell_date,
        car_issue_date,
        customer_fullname,
        book_status_text: car_sell.book_status_text,
        liciense_status_name: car_sell.liciense_status_name,
        receipt_transfer_document_date,
        transfer_document_send_date,
        transfer_document_status_text: car_sell.transfer_document_status_text,
        outstanding_sparepart: car_sell.outstanding_sparepart,
        license_plate_new,
        license_plate_old,
        car_brand_name:  car_sell.car_brand_name,
        car_model_name: car_sell.car_model_name,
        bank_finance_name_new: car_sell.bank_finance_name_new,
        sale_name: car_sell.sale_name,
      }
      return row
    })

      const ExcelJS = require("exceljs/dist/exceljs");
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("ติดตามรถขายแล้ว");

      let titleStyle = {
        name: "AngsanaUPC",
        family: 4,
        size: 18,
        bold: true,
      };

      let contentStyle = {
        name: "AngsanaUPC",
        family: 4,
        size: 16,
        bold: false,
      };

      let contentBoldStyle = {
        name: "AngsanaUPC",
        family: 4,
        size: 16,
        bold: true,
      };

      let border= {
        top: {style:'thin'},
        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      }

      let columns = [
        { header: "ลำดับ", key: "order", width: 6,},
        { header: "ว.ด.ป ที่ขาย", key: "car_sell_date", width: 16,},
        { header: "วันที่ออกรถ/ปล่อยรถ", key: "car_issue_date", width: 16,},
        { header: "ชื่อลูกค้า", key: "customer_fullname", width: 25,},
        { header: "ที่อยู่เล่ม", key: "book_status_text", width: 16,},
        { header: "สถานะเล่ม", key: "liciense_status_name", width: 16,},
        { header: "ว.ด.ป รับเอกสารโอน", key: "receipt_transfer_document_date", width: 16,},
        { header: "สถานะจัดส่งลูกค้า", key: "transfer_document_status_text", width: 16,},
        { header: "ว.ด.ป ส่งเอกสารลูกค้า", key: "transfer_document_send_date", width: 16,},
        { header: "ค้างอะไหล่", key: "outstanding_sparepart", width: 16,},
        { header: "ทะเบียนใหม่", key: "license_plate_new", width: 25,},
        { header: "ทะเบียนเดิม", key: "license_plate_old", width: 25,},
        { header: "ยี่ห้อ", key: "car_brand_name", width: 16,},
        { header: "รุ่น", key: "car_model_name", width: 16,},
        { header: "ไฟแนนซ์", key: "bank_finance_name_new", width: 16,},
        { header: "ขายโดย", key: "sale_name", width: 16,},
      ];

      sheet.columns = columns
      let curr = format(new Date(), 'dd/MM/yyyy HH:mm:ss')
      let rows = [
        ['ติดตามข้อมูลการขาย 1'],
        ['พิมพ์วันที่ : '+curr],
      ]
      sheet.insertRows(1, rows);

      const promise = Promise.all(
          excelData.map(async (car, index) => {
            // data แถวแรก แถวที่ 4
            let rowNumber = index + 4;
            sheet.addRow(car)
            sheet.getRow(rowNumber).eachCell((cell, colNumber ) =>  {
              cell.alignment = { vertical: 'middle', horizontal: 'center' };
              cell.border = border;
            });

            if (rowNumber === 3) {
              sheet.getRow(rowNumber).font = contentBoldStyle
            } else {
              sheet.getRow(rowNumber).font = contentStyle
            }
          }));

      promise.then(() => {
        sheet.getRow(1).font = titleStyle
        sheet.getRow(2).font = contentStyle
        sheet.getRow(3).font = contentBoldStyle
        sheet.getRow(3).border = border
        sheet.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };

        /* for(let i=0; i < excelData.length; i++) {
          let row = i + 3
          let cellName = 'B'+String(row)
          sheet.getCell(cellName).border = border
        } */

        workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          let curr = format(new Date(), 'yyyyMMdd_HHmmss')
          const fileName = 'ติดตามข้อมูลการขาย_export_'+curr+'.xlsx'

          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = fileName;
          anchor.click();
          window.URL.revokeObjectURL(url);
        });

        setTimeout(() => {
          this.setState({
            isLoading: false
          })
        } , 1200);

      });



  }


  render () {
    const { car_sells, liciense_status } = this.state
    const isDateFill = this.props.isDateFill

    let liciense_status_option = liciense_status.map((liciense_status, index) => {
      return (
          <option key={index} value={liciense_status.liciense_status_id}>{liciense_status.liciense_status_name}</option>
      )
    })

    let month_year_list_option = this.state.month_year_list.map((month_year, index) => (
      <option key={index} value={month_year.month_list}>{month_year.month_list_thai}</option>
    ))

    let car_sell_tr = car_sells.map((car_sell, index) => {

      let license_plate_new = car_sell.car_license_plate_new+' : '+car_sell.province_name_new
      let license_plate_old = car_sell.car_license_plate_old+' : '+car_sell.province_name_old
      // let car_brand_name = car_sell.car_brand_name
      // let car_model_name = car_sell.car_model_name
      let car_sell_date = DateUtil.convertCCtoDDMMYYYY(car_sell.car_sell_date)

      let car_issue_date = car_sell.car_issue_date !== null ? DateUtil.convertCCtoDDMMYYYY(car_sell.car_issue_date) : '-'
      let customer_fullname = car_sell.customer_name+' '+car_sell.customer_lastname

      return <tr key={index}>
        <td className="text-center" >
          {
            car_sell.car_edit === 2 ?
              <button
                className={car_sell.car_edit === 1 ? "btn btn-sm btn-success" : (car_sell.car_edit === 2) ? "btn btn-sm btn-warning" : "btn btn-sm " }
                disabled={car_sell.car_edit===3}
                onClick={() => this.onClickSave(car_sell, index)}
              > บันทึก </button>
              :  index+1
          }
        </td>
        <td>{ car_sell_date }</td>
        <td>{ car_issue_date }</td>
        <td>{ customer_fullname }</td>
        <td>

          <input name="book_status_text" style={styles.input}
                 maxLength={50}
                 value={this.state.car_sells[index].book_status_text}
                 onChange={(event)=> {
                   this.onChangeInputRow(car_sell, index, event)
                 }}
          />

        </td>
        <td>
          <select className="form-control"
                  name="liciense_status_id"
                  style={{...styles.selectFilter, width: 100}}
                  value={this.state.car_sells[index].liciense_status_id}
                  onChange={(event)=> {
                      this.onChangeInputRow(car_sell, index, event)
                  }}
          >
            {liciense_status_option}
          </select>
        </td>
        <td style={{width: 90}}>
          <BDatePicker
            isDateFill={isDateFill}
            style={styles.input}
            name={'receipt_transfer_document_date'}
            value={this.state.car_sells[index].receipt_transfer_document_date}
            label={''}
            onUpdateDate={(buddhist_date, christ_date) => {
              // receipt_transfer_document_date
              this.onChangeDate(car_sell, 'receipt_transfer_document_date', christ_date)
            }}
          />
        </td>
        <td>
          <input
            type="text"
            name="transfer_document_status_text"
            style={styles.input}
            maxLength={50}
            value={this.state.car_sells[index].transfer_document_status_text}
            onChange={(event)=> this.onChangeInputRow(car_sell, index, event)}
          />
        </td>
        <td  style={{width: 90}}>
          <BDatePicker
            isDateFill={isDateFill}
            style={styles.input}
            name={'transfer_document_send_date'}
            value={this.state.car_sells[index].transfer_document_send_date}
            label={''}
            onUpdateDate={(buddhist_date, christ_date) => {
              // receipt_transfer_document_date
              this.onChangeDate(car_sell, 'transfer_document_send_date', christ_date)
            }}
          />
        </td>
        <td>
          <input
              type="text"
              name="outstanding_sparepart"
              style={styles.input}
              maxLength={50}
              value={this.state.car_sells[index].outstanding_sparepart}
              onChange={(event)=> this.onChangeInputRow(car_sell, index, event)}
          />
        </td>
        <td> {license_plate_new} </td>
        <td> {license_plate_old} </td>
        <td> {car_sell.car_brand_name} </td>
        <td> {car_sell.car_model_name} </td>
        <td> {car_sell.bank_finance_name_new} </td>
        <td> {car_sell.sale_name} </td>
      </tr>
    })

    return (
      <div className="row">

        <Loading isLoading={this.state.isLoading} />

        <div className="col-sm-12 col-md-12">
          <div className="card card-accent-primary">
            <div className="card-header card-customer">
              <strong> ติดตามข้อมูลการขาย จำนวน {car_sells.length} คัน</strong>
              &nbsp;&nbsp;
              <button
                type="button"
                className="btn btn-sm btn-head-bar ml-2"
                style={styles.buttonHeight}
                onClick={() => {
                  this.setState({
                    sell_month_year: '',
                    car_license_plate: '',
                    outstanding_sparepart: '',
                    isLoading: true
                  }, () => {
                    this.loadCarSellData(null)

                  })
                }}
              >
                <i className="icon-refresh"/>
              </button>

            </div>
            <div className="card-block">
              <div className="row mb-2 pl-3">
                <div className="col-md-12 form-inline">
                <div style={{paddingTop: 6, marginRight: 6}} >เลือก</div>
                <div>
                  <select
                    className="form-control"
                    name="sell_month_year"
                    onChange={(e) => this.sellMonthYearChange(e)}
                  >
                    <option value="no_send_book">เฉพาะลูกค้าที่ยังไม่ส่งเล่ม</option>
                    {month_year_list_option}
                  </select>
                </div>

                <input
                  className="form-control mr-1 ml-2"
                  style={{width: 150}}
                  placeholder={'ทะเบียนรถ'}
                  value={this.state.car_license_plate}
                  name="car_license_plate"
                  onChange={(e) => {
                    this.setState({car_license_plate: e.target.value})
                  }}
                />

                  <input
                    className="form-control mr-1 ml-2"
                    style={{width: 150}}
                    placeholder={'ค้างอะไหล่'}
                    value={this.state.outstanding_sparepart}
                    name="outstanding_sparepart"
                    onChange={(e) => {
                      this.setState({outstanding_sparepart: e.target.value})
                    }}
                  />

                  <button
                    className="btn btn-sm btn-primary mr-1 ml-2"
                    onClick={() => {
                      let sell_month_year = this.state.sell_month_year

                      if(sell_month_year !== '') {
                        this.loadCarSellData(sell_month_year)
                      } else {
                        this.loadCarSellData(null)
                      }

                    }}
                    style={{...styles.buttonHeight, marginTop: 2}}
                  >
                    <i className="icon-search"/>ค้นหา
                  </button>

                  <button
                      className="btn btn-sm btn-success mr-1 ml-2"
                      onClick={() => this.exportExcelCarSell()}
                      style={{...styles.buttonHeight, marginTop: 2}}
                  >
                    <i className="icon-search"/> Excel
                  </button>


                </div>
              </div>
              <div className="row">
                <div className="col-12 tableFixHead">
                    <table className="table table-striped table-responsive" >
                      <thead>
                      <tr>
                        <th className="text-center" style={{width: 28}}>ลำดับ</th>
                        <th style={{width: 100}} className=" text-nowrap">ว.ด.ป ที่ขาย</th>
                        <th style={{width: 100}} className=" text-nowrap">วันที่ออกรถ/ปล่อยรถ</th>
                        <th style={{width: 100}} className=" text-nowrap">ชื่อลูกค้า</th>
                        <th style={{width: 100}} className=" text-nowrap">ที่อยู่เล่ม</th>
                        <th style={{width: 100}} className=" text-nowrap">สถานะเล่ม</th>
                        <th style={{width: 100}} className=" text-nowrap">ว.ด.ป รับเอกสารโอน</th>
                        <th style={{width: 100}} className=" text-nowrap">สถานะจัดส่งลูกค้า</th>
                        <th style={{width: 100}} className=" text-nowrap">ว.ด.ป ส่งเอกสารลูกค้า</th>
                        <th style={{width: 100}} className=" text-nowrap">ค้างอะไหล่</th>
                        <th style={{width: 100}} className=" text-nowrap">ทะเบียนใหม่</th>
                        <th style={{width: 100}} className=" text-nowrap">ทะเบียนเดิม</th>
                        <th style={{width: 100}} className=" text-nowrap">ยี่ห้อ</th>
                        <th style={{width: 100}} className=" text-nowrap">รุ่น</th>
                        <th style={{width: 100}} className=" text-nowrap">ไฟแนนซ์</th>
                        <th style={{width: 100}} className=" text-nowrap">ขายโดย</th>
                      </tr>
                      </thead>
                      <tbody>
                      {car_sell_tr}
                      </tbody>
                    </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

const styles = {
  input: {
    width: 100,
  }
}

export default TrackInformationCarSell
