import React, {Component} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {APIURL} from "../../../config/config";
import Loading from "../../Loading";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import CustomerStoreFrontForm from "../CustomerStoreFrontForm/CustomerStoreFrontForm";
import CustomerStoreFrontListRow from "../CustomerStoreFrontListRow/CustomerStoreFrontListRow";
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";
import {AlertSuccess} from "../../Alert/Alert";
import CustomerStoreFrontEdit from "../CustomerStoreFrontEdit/CustomerStoreFrontEdit";
import {save} from "save-file";


class CustomerStoreFrontList extends Component{
    constructor (props) {
        super(props)

        this.state = {
            activeTab: '1',
            lists: [],
            load_data: true,
            danger: false,
            user: JSON.parse(this.props.user),
            isLoading: false,
            customerStoreFront:{},
            customer_id: 0,
            customer_type : 'walkin',
            is_search: false,
            action: "บันทึก",
            customer: '' ,
            toggle_customer_store_front: false,
            customerstorefrontedit: false,
            selectCustomer:'',
            employee:[],
            employee_id: '',
            walkin_level_list:[
                {walkin_level_id: 'A', walkin_level_name: 'A'},
                {walkin_level_id: 'B', walkin_level_name: 'B'},
                {walkin_level_id: 'C', walkin_level_name: 'C'},
                {walkin_level_id: 'D', walkin_level_name: 'D'},
                {walkin_level_id: 'E', walkin_level_name: 'E'}
            ],
            walkin_level: '',
            //
            itemPerPage: 30,
            itemCount: 0,
            pageCount: 0,
            currentPageIndex: 0
            //page: 1,
            //customer_all: 0,
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.loadDataStoreFrontPage = this.loadDataStoreFrontPage.bind(this);
        this.toggleCustomerStoreFront = this.toggleCustomerStoreFront.bind(this);
        this.updateCustomerStoreFront = this.updateCustomerStoreFront.bind(this);
        this.toggleCustomerStoreFrontEdit = this.toggleCustomerStoreFrontEdit.bind(this);
        this.toggleDangerDelete = this.toggleDangerDelete.bind(this);
        this.loadEmployeeData = this.loadEmployeeData.bind(this);
        this.loadCustomerStoreFrontData = this.loadCustomerStoreFrontData.bind(this);

    }

    //ไม่ได้ใช้ 4-01-68
    /*loadCustomerStoreFront(){
        axios.get(APIURL + '/customer/get_customer_walkin')
            .then(res => {
                alert (res.data.length)
                this.setState({lists: res.data, load_data: false, customer_id: 0, danger: false})
            }).catch(error => {
            //alert (error)
            this.setState({lists: [], load_data: true, customer_id: 0, danger: false})
        })
    }*/

    loadDataStoreFrontPage (page) {
        this.setState({
            isLoading: true
        }, () => {
            axios.get(APIURL + '/customer/walkin/'+page)
                .then(res => {
                    //alert (res.data.length)

                    let customers =  res.data.customers
                    let customer_all =  res.data.customer_all

                    this.setState({
                        lists: customers,
                        customer_all: customer_all,
                        load_data: false,
                        customer_id: 0,
                        danger: false,
                        isLoading: false,
                        toggle_customer_store_front: false,
                        customer_type : 'walkin',
                        page: page
                    })
                }).catch(error => {
                //alert (error)
                this.setState({lists: [], load_data: true, customer_id: 0, danger: false, isLoading: false})
            })
        })
    }

    loadEmployeeData () {
        axios.get(APIURL + '/employee/')
            .then(res => {
                this.setState({employee: res.data})
            }).catch(error => {
            this.setState({employee: []})
        })
    }

    loadCustomerStoreFrontData(){
        this.setState({
            isLoading: true
        })

        const employee_id = this.state.employee_id
        const walkin_level = this.state.walkin_level

        const data = {
            employee_id: employee_id,
            walkin_level: walkin_level
        }

        axios.post(APIURL + '/report/customerstorefrontdata', data)
            .then(res => {
                this.setState({
                    isLoading: false,
                    lists: res.data
                })

            }).catch(error => {
            console.log('error:', error)
        })
    }

    toggleCustomerStoreFront (customer) {
        let isClose = !this.state.toggle_customer_store_front

        this.setState({
            toggle_customer_store_front: isClose
        },()=>{
        if (isClose === false) {
        let page = this.state.page
        this.loadDataStoreFrontPage(page);
        }
        });
    }

    deleteCustomer (customer) {
        this.setState({customer_id: customer.customer_id, customer:customer})
        this.toggleDanger()
    }

    editCustomer (customer) {
        //this.setState({action: "แก้ไข", customer: customer, customer_id: customer.customer_id});
        this.setState ({selectCustomer: customer})
        this.toggleCustomerStoreFrontEdit()
        //alert(customer);
    }

    toggleCustomerStoreFrontEdit() {
        this.setState({
            customerstorefrontedit: !this.state.customerstorefrontedit
        });
    }

    toggleDanger () {
        this.setState({
            danger: !this.state.danger
        })
    }

    toggleDangerDelete (id) {
        axios.delete(APIURL + '/customer/' + id).then(res => {
            if (res.status === 200) {
                let page = this.state.page
                // this.loadData()
                this.loadDataStoreFrontPage(page)
                this.setState({action: "บันทึก"});
            }
        })
    }

    addCustomerStoreFront (event){
        event.preventDefault()
        let that = this ;
        let customer_name = event.target.customer_name.value
        let customer_lastname = event.target.customer_lastname.value
        let customer_card_id = event.target.customer_card_id.value
        let customer_mobile = event.target.customer_mobile.value
        let walkin_date = event.target.walkin_date.value
        let walkin_level = event.target.walkin_level.value
        let walkin_note = event.target.walkin_note.value
        let customer_type = event.target.customer_type.value
        let employee_id = event.target.employee_id.value

        event.target.customer_name.value = ""
        event.target.customer_lastname.value = ""
        event.target.customer_card_id.value = ""
        event.target.customer_mobile.value = ""
        event.target.customer_address.value = ""

        event.target.walkin_date.value = ""
        event.target.walkin_level.value = ""
        event.target.walkin_note.value = ""
        event.target.customer_type.value = ""
        event.target.employee_id.value = ""

        axios.post(APIURL + '/customer/', {
            customer_name: customer_name,
            customer_lastname: customer_lastname,
            customer_card_id : customer_card_id,
            customer_mobile: customer_mobile,
            walkin_date : walkin_date,
            walkin_level : walkin_level,
            walkin_note : walkin_note,
            customer_type : customer_type,
            employee_id: employee_id,
            status_field: 'show',
            username: 'admin'
        })
            .then(function (response) {

                //that.loadCustomerStoreFront()
                that.setState({ customer: '', customer_id: 0, action: "บันทึก"})
            })
            .catch(function (error) {
            })
    }

    updateCustomerStoreFront(event){
        event.preventDefault()

        let page = this.state.page;
        let that = this ;
        let customer_id = event.target.customer_id.value ;
        let customer_name = event.target.customer_name.value
        let customer_lastname = event.target.customer_lastname.value
        let customer_card_id = event.target.customer_card_id.value
        let customer_mobile = event.target.customer_mobile.value
        //
        let walkin_date = event.target.walkin_date.value
        let walkin_level = event.target.walkin_level.value
        let walkin_note = event.target.walkin_note.value
        let employee_id = event.target.employee_id.value
        //let customer_type = event.target.customer_type.value
        if(customer_name===''){
            return ;
        }
        event.target.customer_name.value = ""
        event.target.customer_lastname.value = ""
        event.target.customer_card_id.value = ""
        event.target.customer_mobile.value = ""
        //
        event.target.walkin_date.value = ""
        event.target.walkin_level.value = ""
        event.target.walkin_note.value = ""
        event.target.employee_id.value = ""
        //event.target.customer_type.value = ""

        axios.put(APIURL + '/customer/'+customer_id, {
            customer_id:customer_id,
            customer_name: customer_name,
            customer_lastname: customer_lastname,
            customer_card_id : customer_card_id,
            customer_mobile: customer_mobile,

            walkin_date : walkin_date,
            walkin_level : walkin_level,
            walkin_note : walkin_note,
            employee_id : employee_id,
            //customer_type : customer_type,
            status_field: 'show',
            username: 'admin'
        })
            .then(function (response) {

                // that.loadData()
                AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');

                that.loadDataStoreFrontPage(page)

                that.setState({ customer: '', customer_id: 0, action: "บันทึก"})
                that.toggleCustomerStoreFrontEdit();
            })
            .catch(function (error) {
            })
    }

    changeCustomerStoreFront(event){
        let xname =event.target.name
        if(xname==="customer_name"){
            this.setState ({customer:{
                    customer_name: event.target.value
                }})
        }else if(xname==="customer_lastname"){
            this.setState ({customer:{
                    customer_lastname: event.target.value
                }})
        }else if(xname==="customer_card_id"){
            this.setState ({customer:{
                    customer_card_id: event.target.value
                }})
        }else if(xname==="customer_mobile"){
            this.setState ({customer:{
                    customer_mobile : event.target.value
                }})
        }else if(xname==="walkin_date"){
            this.setState ({customer:{
                    walkin_date : event.target.value
                }})
        }else if(xname==="walkin_level"){
            this.setState ({customer:{
                    walkin_level : event.target.value
                }})
        }else if(xname==="walkin_note"){
            this.setState ({customer:{
                    walkin_note : event.target.value
                }})
        }
    }

    componentWillMount () {
        this.setState({
            user: JSON.parse(this.props.user)
        })
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    toggle(tab) {
        // console.log(tab)
        // console.log(typeof tab)
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    onSearch(){
        let search_text = this.refs.input_search.value

        if(search_text==='')
            return;

        axios.get( APIURL + '/customer/search/walkin/'+search_text )
            .then(res => {
                this.setState({
                    lists: res.data, load_data: false, customer_id: 0, danger: false, is_search: true })
            }).catch(error => {
            this.setState({lists: [], load_data: true, customer_id: 0, danger: false})
        })
    }

    onRefresh(){
        this.refs.input_search.value = ''
        // this.loadData()
        this.setState({
            is_search: false,
            employee_id: '',
            walkin_level : '',
        })
        this.loadDataStoreFrontPage(1)
    }

    componentDidMount () {
        CarsUtil.removeCarSelect()
        CarSellUtil.removeCarSellSelect()

        this.loadEmployeeData();

        //this.loadCustomerStoreFront();
        this.loadDataStoreFrontPage(1)

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    async exportCustomerStoreFrontReport(){
        const employee_id = this.state.employee_id
        const walkin_level = this.state.walkin_level

        this.setState({
            isLoading: true
        })

        const data = {
            employee_id: employee_id,
            walkin_level: walkin_level
        }

        axios.post(APIURL + '/report/customerstorefrontexcel', data)
            .then(async res => {

                const excelBuffer = res.data.excelBuffer
                const currentDatetime = res.data.currentDatetime
                const fileName = 'รายงานลูกค้าหน้าร้าน_'+currentDatetime+'.xlsx'

                this.setState({
                    isLoading: false
                })

                await save(excelBuffer, fileName)

            }).catch(error => {
        })
        /*this.setState({
            isLoading: true
        }, () => {
            axios.post(APIURL + '/report/customerstorefrontexcel')
                .then(async res => {

                    const excelBuffer = res.data.excelBuffer
                    const currentDatetime = res.data.currentDatetime
                    const fileName = 'รายงานลูกค้าหน้าร้าน_export'+'.xlsx'

                    this.setState({
                        isLoading: false
                    })

                    await save(excelBuffer, fileName)

                }).catch(error => {
            })
        })*/
    }


    render(){

        let width = this.state.width

        let employee_list = this.state.employee.map((employee, index) => {
            return (
                <option key={index+1} value={employee.employee_id}>{employee.employee_name}</option>
            )
        })

        let walkin_level_list = this.state.walkin_level_list.map((walkin_level, index) =>{
            return (
                <option key={index} value={walkin_level.walkin_level_name}> {walkin_level.walkin_level_name} </option>
            )
        })

        return (

            <div className="col-md-12 mb-4">
                <Loading isLoading={this.state.isLoading} />

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                        >
                            <i className="icon-list"/>&nbsp; ข้อมูลลูกค้าหน้าร้าน
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-accent-success">
                                    <div className="card-header card-customer">
                                        <div className="row col-12 form-inline float-left ml-1 mt-3">
                                            <strong className="text-title"> รายชื่อลูกค้าหน้าร้าน </strong>
                                            <input
                                                ref="input_search"
                                                style={styles.inputSearch}
                                                className="form-control mr-1"
                                                placeholder="ค้นหาตามชื่อ หรือ เบอร์โทร"
                                                type="text"
                                            />

                                            <button
                                                className="btn btn-sm btn-head-bar mr-1"
                                                onClick={this.onSearch.bind(this)}
                                                style={styles.buttonHeight}
                                            >
                                                <i className="icon-search"/>ค้นหา
                                            </button>

                                            <button className="btn btn-head-bar"
                                                    style={styles.buttonHeight}
                                                    onClick={this.onRefresh.bind(this)}
                                            >
                                                <i className="icon-refresh"/>
                                            </button>
                                        </div>

                                        <div className="row col-12 form-inline float-left mt-3 mb-3">
                                            <select className="form-control mr-1"
                                                    style={{width : 200}}
                                                    name="walkin_level"
                                                    value={this.state.walkin_level}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            walkin_level: e.target.value
                                                        })
                                                    }}
                                            >
                                                <option value=''> ระดับความต้องการ </option>
                                                {walkin_level_list}
                                            </select>

                                            <select className="form-control mr-1"
                                                    style={{width : 200}}
                                                    id="employee_id"
                                                    name="employee_id"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            employee_id: e.target.value
                                                        })
                                                    }}
                                            >
                                                <option value=''> เซลล์ผู้ดูแลทั้งหมด </option>
                                                {employee_list}
                                            </select>

                                            <button type="button" style={{width: 80,...styles.buttonHeight}}
                                                    className="btn btn-sm btn-primary" onClick={() => this.loadCustomerStoreFrontData()} > ตกลง
                                            </button>
                                        </div>

                                        <div className="float-right">
                                            <button type="button"
                                                    onClick={this.toggleCustomerStoreFront}
                                                    className="btn btn-head-bar ml-4">
                                                <i className="icon-plus"/>&nbsp; เพิ่มข้อมูลลูกค้าหน้าร้าน
                                            </button>

                                            {
                                                this.state.user.type === 'admin' || this.state.user.type === 'manager'
                                                || this.state.user.type === 'account'
                                                    ? (
                                                        <button type="button" className="btn btn-success ml-2" onClick={() => this.exportCustomerStoreFrontReport()}>
                                                            <i className="icon-doc"/>&nbsp; รายงานลูกค้าหน้าร้าน
                                                        </button>
                                                    ) : null
                                            }
                                        </div>
                                    </div>

                                    <Modal isOpen={this.state.toggle_customer_store_front} toggle={this.toggleCustomerStoreFront}
                                           className={'modal-lg ' + this.props.className}
                                           style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}>
                                        <ModalBody>
                                            <CustomerStoreFrontForm
                                                onToggle={this.toggleCustomerStoreFront}
                                                username={this.state.user.user}
                                            />
                                        </ModalBody>
                                    </Modal>

                                    <CustomerStoreFrontListRow
                                        customer_list={this.state.lists}
                                        customer_all = {this.state.customer_all}
                                        onLoadDataPage={this.loadDataStoreFrontPage}
                                        is_search={this.state.is_search}
                                        customerStoreFrontCallbacks={
                                            {
                                                delete: this.deleteCustomer.bind(this),
                                                edit: this.editCustomer.bind(this),
                                            }
                                        }
                                    />

                                    <Modal isOpen={this.state.customerstorefrontedit} toggle={this.toggleCustomerStoreFrontEdit}
                                           className={'modal-lg ' + this.props.className}
                                           style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}>
                                        <ModalBody>
                                            <CustomerStoreFrontEdit
                                                onToggle={this.toggleCustomerStoreFrontEdit}
                                                customer={this.state.selectCustomer}
                                                onCustomerStoreFrontSubmit={
                                                    {
                                                        add: this.addCustomerStoreFront.bind(this),
                                                        update: this.updateCustomerStoreFront.bind(this),
                                                        change: this.changeCustomerStoreFront.bind(this)
                                                    }
                                                }
                                                actionType={this.state.action}
                                                customers={this.state.customer}

                                            />
                                        </ModalBody>
                                    </Modal>

                                    <Modal isOpen={this.state.danger} toggle={this.toggleDanger.bind(this)}
                                           className={'modal-danger'}>
                                        <ModalHeader toggle={this.toggleDanger.bind(this)}>ลบข้อมูล</ModalHeader>
                                        <ModalBody>
                                            <strong> คุณต้องการลบข้อมูล {this.state.customer.customer_name} ใช่หรือไม่ ?? </strong>
                                            <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="primary"
                                                    onClick={this.toggleDangerDelete.bind(this, this.state.customer_id)}>ตกลง</Button>{' '}
                                            <Button color="secondary" onClick={this.toggleDanger.bind(this)}>ยกเลิก</Button>

                                        </ModalFooter>
                                    </Modal>

                                </div>
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
            </div>

        )

    }

}



const styles = {
    inputSearch: {
        marginLeft: 20,
        width: 300,
        display: 'inline'
    },

    buttonHeight: {
        height: 36,
        paddingTop:0,
        paddingBottom:0,
        display: 'inline',
        marginTop: '0.20rem',
        borderRadius:5,
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CustomerStoreFrontList);