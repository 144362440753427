import React, { Component } from 'react'
// import DatePicker from 'react-datepicker'
import MaskedInput  from 'react-maskedinput'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat from 'react-number-format'
import axios from 'axios'
import {APIURL, PORT} from '../../../config/config'
import OccupationModal from '../OccupationModal'
import {format} from "date-fns";
import {DateUtil} from "../../../utils/dateUtil";
import {AlertSuccess, AlertWarning} from "../../Alert/Alert";
import {checkFormControls} from "../../../services/grouppermission";
import BDatePicker from "../../BDatePicker";
import { CarUtil } from '../../../utils/carUtil'
import SelectSearch from "react-select-search";
moment.locale('th');

export  default class CustomerEdit extends Component{
    constructor(props){
        super(props);
        this.state={
            lists: [],
            sales_type:[],
            load_data: true,
            danger: false,
            action: "บันทึก",
            customer: '' ,

            customer_id: this.props.customer.customer_id,
            customer_name: this.props.customer.customer_name,
            customer_lastname:this.props.customer.customer_lastname,
            customer_card_id:this.props.customer.customer_card_id,
            customer_birth_mask: this.props.customer.customer_birth === null || this.props.customer.customer_birth === '0000-00-00' ? '' : this.convertCCtoBB(moment(this.props.customer.customer_birth).format("YYYY-MM-DD")),
            customer_birth: this.props.customer.customer_birth === null || this.props.customer.customer_birth === '0000-00-00' ? '' : moment(this.props.customer.customer_birth).format("YYYY-MM-DD"),
            customer_age: this.props.customer.customer_age === null ? '' : this.props.customer.customer_age,
            customer_mobile:this.props.customer.customer_mobile,
            customer_mobile2:this.props.customer.customer_mobile2,
            customer_address:this.props.customer.customer_address,
            guarantor_name:this.props.customer.guarantor_name,
            guarantor_address:this.props.customer.guarantor_address,
            guarantor_mobile:this.props.customer.guarantor_mobile,
            customer_income: this.props.customer.customer_income,
            occupation_id: this.props.customer.occupation_id,
            occupation_name: this.props.customer.occupation_name,
            province_id: this.props.customer.province_id,
            employee_id: this.props.customer.employee_id,
            //customer_date_book: this.props.customer.customer_date_book,
            customer_date_book_mask: this.props.customer.customer_date_book === null || this.props.customer.customer_date_book === '0000-00-00' ? '' : this.convertCCtoBB(moment(this.props.customer.customer_date_book).format("YYYY-MM-DD")),
            customer_date_book: this.props.customer.customer_date_book === null || this.props.customer.customer_date_book === '0000-00-00' ? '' : moment(this.props.customer.customer_date_book).format("YYYY-MM-DD"),
            customer_note: this.props.customer.customer_note,

            customer_line: this.props.customer.customer_line === null ? '' : this.props.customer.customer_line,
            customer_facebook: this.props.customer.customer_facebook === null ? '' : this.props.customer.customer_facebook,
            employee_admin_id: this.props.customer.employee_admin_id === undefined ? 0 : this.props.customer.employee_admin_id,
            customer_sell_type_id: this.props.customer.customer_sell_type_id === undefined ? '0' : this.props.customer.customer_sell_type_id,
            customer_grade_id: this.props.customer.customer_grade_id === undefined ? '0' : this.props.customer.customer_grade_id,

            employment_type: this.props.customer.employment_type === null ? '' : this.props.customer.employment_type,
            work_experience: this.props.customer.work_experience === null ? '' : this.props.customer.work_experience,
            salary_channel: this.props.customer.salary_channel === null ? '' : this.props.customer.salary_channel,
            salary_receipt_type: this.props.customer.salary_receipt_type === null ? '' : this.props.customer.salary_receipt_type,
            customer_car_want: this.props.customer.customer_car_want === null ? '' : this.props.customer.customer_car_want,
            date_oneyear_employment: this.props.customer.date_oneyear_employment === null ? null : moment(this.props.customer.date_oneyear_employment).format("YYYY-MM-DD"),

          province: [],
          employee:[],
          employee_admins:[],
          startDateCustomerEdit1: moment(),
          startDateCustomerEditDateBook: moment(),
          isOccupationModal: false,
            employment_types: [],
            work_experiences: [],
            salary_channels: [],
            salary_receipt_types: [],
          car_type_interests: [],
            form_controls: [],
          car_brands: [],
          car_models: [],
            car_sell_type: [],
          car_type_interest_id: '0',
            customer_grades: [],
          car_brand_id: '',
          car_model_id: '',
          installment_payment: '',
          customer_find_car_id: 0
        }

        this.handleDateCustomerEdit1 = this.handleDateCustomerEdit1.bind(this)
        this.convertBBtoCC = this.convertBBtoCC.bind(this)
        this.convertCCtoBB =  this.convertCCtoBB.bind(this)
        this.onMaskedDateCustomerEdit1 = this.onMaskedDateCustomerEdit1.bind(this)
        this.onInputChangeCustomer = this.onInputChangeCustomer.bind(this)
        this.loadProvinceData = this.loadProvinceData.bind(this)
        this.onSelectOccupation = this.onSelectOccupation.bind(this)

        this.loadEmployeeData = this.loadEmployeeData.bind(this)
        this.onMaskedDateCustomerEditDateBook = this.onMaskedDateCustomerEditDateBook.bind(this)
        this.handleDateCustomerEditDateBook = this.handleDateCustomerEditDateBook.bind(this)
        this.loadCustomerOptions = this.loadCustomerOptions.bind(this)
        this.updateCustomer = this.updateCustomer.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.loadFormControls = this.loadFormControls.bind(this)
        this.handleCarBrandChange = this.handleCarBrandChange.bind(this)
        this.loadCarBrandData = this.loadCarBrandData.bind(this)
        this.loadCarModel = this.loadCarModel.bind(this)
        this.loadCarSellTypeData = this.loadCarSellTypeData.bind(this)
    }

    componentDidMount(){

        this.loadCarBrandData()
        this.loadProvinceData()
        this.loadEmployeeData()
        this.loadCustomerOptions()
        this.loadFormControls()
        this.loadCarSellTypeData()

        // console.log(this.props.customer)

      if (this.props.customer.customer_find_cars !== undefined) {
        if (this.props.customer.customer_find_cars.length !== 0) {
          let customer_find_car = this.props.customer.customer_find_cars[0]
          // let customer = this.props.customer
          // console.log(customer_find_car)
          // console.log('car_type_interest_id', customer.car_type_interest_id)
          // console.log('customer_grade_id', customer.customer_grade_id)
          //  car_type_interest_id: customer.car_type_interest_id,
          //  customer_grade_id: customer.customer_grade_id,

          this.setState({
            car_type_interest_id: customer_find_car.car_type_interest_id,
            car_brand_id: customer_find_car.car_brand_id,
            car_model_id: customer_find_car.car_model_id,
            installment_payment: customer_find_car.installment_payment,
            customer_find_car_id: customer_find_car.id,
          }, () => {
            if (customer_find_car.car_brand_id !== 0) {
              this.loadCarModel(customer_find_car.car_brand_id)
            }
          })
        }
      }

    }

    loadCarSellTypeData () {
        axios.get(APIURL + '/car_sell_type/')
            .then(res => {
                this.setState({car_sell_type: res.data})
                //alert (res.data)
            }).catch(error => {
            this.setState({car_sell_type: []})
        })
    }

    loadFormControls() {
        let tableName = "customer";
        axios.get(APIURL + '/form_controls/'+tableName)
            .then(res => {
                this.setState({form_controls: res.data});
            }).catch(error => {
            this.setState({form_controls: []})
        })
    }

    loadCustomerOptions () {
        axios.get(APIURL + '/customer/option/data')
            .then(res => {
                
                let employment_types = res.data.employment_types
                let work_experiences = res.data.work_experiences
                let salary_channels = res.data.salary_channels
                let salary_receipt_types = res.data.salary_receipt_types
                let car_type_interests = res.data.car_type_interests
                let customer_grades = res.data.customer_grades

                this.setState({
                    employment_types: employment_types,
                    work_experiences: work_experiences,
                    salary_channels: salary_channels,
                    salary_receipt_types: salary_receipt_types,
                    car_type_interests: car_type_interests,
                    customer_grades: customer_grades
                })
            }).catch(error => {
            console.log('error:', error)
            // this.setState({employee: []})
        })
    }

  onSelectOccupation(occupation) {
    this.setState({
      occupation: occupation,
      occupation_id: occupation.occupation_id,
      occupation_name: occupation.occupation_name,
      isOccupationModal: false
    })
  }

    loadProvinceData () {
      axios.get(APIURL + '/province/')
        .then(res => {
          this.setState({province: res.data})
        }).catch(error => {
        this.setState({province: []})
      })
    }

    loadEmployeeData () {
        axios.get(APIURL + '/employee/')
            .then(res => {
                let data = res.data
                let saleFilter = data.filter(d => d.employee_position.indexOf('ขาย') !== -1);
                let adminFilter = data.filter(d => d.employee_position.indexOf('แอดมิน') !== -1);
                this.setState({
                    employee: saleFilter,
                    employee_admins: adminFilter
                });
        }).catch(error => {
            this.setState({employee: [], employee_admins: []});
        })
    }

    handleDateCustomerEdit1(date) {
        // this.setState({
        //     startDateCustomerEdit1: moment(date),
        //     customer_birth : date
        // });
        // ---
        let customer_birth = format(new Date(date),'yyyy-MM-dd');
        let years = DateUtil.calculateAge(customer_birth);

        // startDateCustomerNew1: moment(date).format('YYYY-MM-DD')
        this.setState({
            startDateCustomerNew1: format(new Date(date),'yyyy-MM-dd'),
            customer_birth: customer_birth,
            customer_age: years
        });
    }

    handleDateCustomerEditDateBook(date) {
        //alert (date);
        this.setState({
            startDateCustomerEditDateBook: moment(date),
            customer_date_book : date
        });
    }

    onMaskedDateCustomerEdit1(e){

        let buddhist_date = e.target.value ;

        this.setState({
            customer_birth_text: buddhist_date
        })

        if (buddhist_date.length===0) {
            this.setState({
                customer_birth: null
            })
        }

      if(buddhist_date.indexOf('_') === -1 ) {
        if (buddhist_date.length === 10) {
          let cc_date = this.convertBBtoCC(buddhist_date)
          this.handleDateCustomerEdit1(cc_date)
        }
      }
    }

    onMaskedDateCustomerEditDateBook(e){
        let buddhist_date = e.target.value ;

        if(buddhist_date.indexOf('_') === -1 ) {
            if (buddhist_date.length === 10) {
                let cc_date = this.convertBBtoCC(buddhist_date)
                this.handleDateCustomerEditDateBook(cc_date)
            }
        }
    }

    convertBBtoCC(buddhist_date){
        // dd-mm-yyyy to yyyy-mm-dd
        let date_arr = buddhist_date.split('-');
        let dd = date_arr[0];
        let mm = date_arr[1];
        let yyyy = Number(date_arr[2])-543;
        let cc_date= yyyy+'-'+mm+'-'+dd ;
        if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
            //alert(cc_date);
            return cc_date ;
        }
        return moment() ;
    }

    convertCCtoBB(cc_date) { // "1972-10-31"
      let date_arr=cc_date.split('-');
      let dd = date_arr[2];
      let mm = date_arr[1];
      let yyyy = Number(date_arr[0])+543;

      if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
        //alert(cc_date);
        return dd+'-'+mm+'-'+yyyy ;
      }
      return moment() ;
    }

    handleOnChange(e) {
        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [name]: value
        })
    }

    onInputChangeCustomer(event) {
        event.preventDefault()

      let name = event.target.value
      let id = event.target.id

      this.setState({[id]: name});

     /* if (event.target.id === 'customer_name') {
            this.setState({customer_name: name});
        } else if (event.target.id === 'customer_lastname') {
            this.setState({customer_lastname: name})
        } else if (event.target.id === 'customer_card_id') {
            this.setState({customer_card_id: name})
        } else if (event.target.id === 'customer_address') {
            // name = this.refs.customer_address.val;
            this.setState({customer_address: name})
        }else if (event.target.id === 'customer_birth') {
            this.setState({customer_birth: name})
        }else if (event.target.id === 'customer_age') {
            this.setState({customer_age: name})
        }else if (event.target.id === 'customer_mobile') {
            this.setState({customer_mobile: name})
        }else if (event.target.id === 'guarantor_name') {
            this.setState({guarantor_name: name})
        }else if (event.target.id === 'guarantor_address') {
            this.setState({guarantor_address: name})
        }else if (event.target.id === 'guarantor_mobile') {
            this.setState({guarantor_mobile: name})
        }else if (event.target.id === 'customer_note') {
          this.setState({customer_note: name})
        }else if (event.target.id === 'customer_date_book') {
          this.setState({customer_date_book: name})
        }else if (event.target.id === 'customer_line'){
          this.setState({customer_line: name})
        }else if (event.target.id === 'customer_facebook'){
          this.setState({customer_facebook: name})
        } */
    }


    updateCustomer(event){
        event.preventDefault()

        let customer_id = event.target.customer_id.value ;
        let customer_name = event.target.customer_name.value
        let customer_lastname = event.target.customer_lastname.value
        // let customer_card_id = event.target.customer_card_id.value
        let customer_card_id = this.state.customer_card_id

        let customer_birth = event.target.customer_birth.value
        let customer_age = event.target.customer_age.value
        let customer_mobile = event.target.customer_mobile.value
        let customer_address = event.target.customer_address.value
        //
        let occupation_id = event.target.occupation_id.value
        let customer_income = event.target.customer_income.value
        let province_id = event.target.province_id.value
        //alert(customer_name);
        let guarantor_name = event.target.guarantor_name.value
        let guarantor_address = event.target.guarantor_address.value
        let guarantor_mobile = event.target.guarantor_mobile.value

        let employee_id = event.target.employee_id.value
        let customer_date_book_text = event.target.customer_date_book_text.value
        //let customer_date_book = customer_date_book_text === '' ? '' : this.state.startDateCustomerDateBook
        let customer_date_book = event.target.customer_date_book.value
        let customer_note = event.target.customer_note.value

        let customer_line = event.target.customer_line.value
        let customer_facebook = event.target.customer_facebook.value

        let employment_type = this.state.employment_type
        let work_experience = this.state.work_experience
        let salary_channel = this.state.salary_channel
        let salary_receipt_type = this.state.salary_receipt_type
        let date_oneyear_employment = this.state.date_oneyear_employment
        let customer_car_want = this.state.customer_car_want
        let customer_mobile2 = this.state.customer_mobile2

        let username = this.props.username

      let car_type_interest_id = this.state.car_type_interest_id
      let car_brand_id = this.state.car_brand_id
      let car_model_id = this.state.car_model_id
      let installment_payment = this.state.installment_payment
      let employee_admin_id = this.state.employee_admin_id
      let customer_sell_type_id = this.state.customer_sell_type_id
      let customer_grade_id = this.state.customer_grade_id

      let customer_find_car_data = {
        car_type_interest_id,
        car_brand_id,
        car_model_id,
        installment_payment
      }

      let customer_find_car = CarUtil.isCustomerFindCarFill(customer_find_car_data) ? customer_find_car_data : null

      if (customer_find_car !== null) {
        let customer_find_car_id = this.state.customer_find_car_id
        customer_find_car = {...customer_find_car, username: username, customer_find_car_id: customer_find_car_id}
      }

      // console.log(customer_find_car)
      // return

        if(customer_name===''){
            AlertWarning('กรุณากรอกข้อมูลลูกค้า');
            return ;
        }

        if (PORT === 9021) {
            if(customer_mobile===''){
                AlertWarning('กรุณากรอกเบอร์โทรลูกค้า');
                return ;
            }
            if(guarantor_mobile===''){
                AlertWarning('กรุณากรอกเบอร์โทรผู้ค้ำ');
                return ;
            }
        }

        event.target.customer_name.value = ""
        event.target.customer_lastname.value = ""
        event.target.customer_card_id.value = ""
        event.target.customer_birth.value = ""
        event.target.customer_age.value = ""
        event.target.customer_mobile.value = ""
        event.target.customer_address.value = ""
        //
        event.target.occupation_id.value = ""
        event.target.customer_income.value = ""
        event.target.province_id.value = ""
        //
        event.target.guarantor_name.value = ""
        event.target.guarantor_address.value = ""
        event.target.guarantor_mobile.value = ""

        event.target.employee_id.value = ""
        //event.target.customer_date_book_text.value = ""
        event.target.customer_date_book.value = ""
        event.target.customer_note.value = ""

        event.target.customer_line.value = ""
        event.target.customer_facebook.value = ""
        event.target.customer_car_want.value = ""

        let data = {
            customer_id:customer_id,
            customer_name: customer_name,
            customer_lastname: customer_lastname,
            customer_card_id : customer_card_id,
            customer_birth: customer_birth,
            customer_age : customer_age,
            customer_mobile: customer_mobile,
            customer_address : customer_address,
            status_field: 'show',
            username: username,
            guarantor_name: guarantor_name,
            guarantor_address: guarantor_address,
            guarantor_mobile: guarantor_mobile,
            occupation_id: occupation_id,
            customer_income: customer_income,
            province_id: province_id,
            employee_id: employee_id,
            customer_date_book: customer_date_book,
            customer_note: customer_note,
            customer_line: customer_line,
            customer_facebook: customer_facebook,
            employment_type: employment_type,
            work_experience: work_experience,
            salary_channel: salary_channel,
            salary_receipt_type: salary_receipt_type,
            date_oneyear_employment: date_oneyear_employment,
            customer_car_want: customer_car_want,
            customer_mobile2: customer_mobile2,
            employee_admin_id: employee_admin_id,
            customer_sell_type_id: customer_sell_type_id,
            customer_grade_id: customer_grade_id,
          form: 'customer',
          customer_find_car: customer_find_car
        }
        // console.log(data)
        // return

        axios.put(APIURL + '/customer/'+customer_id, data)
            .then((res) =>{

                // that.loadData()
                AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
                this.props.onReloadCustomer()

            })
            .catch(function (error) {

            })
    }

  loadCarBrandData () {
    axios.get(APIURL + '/car_brand/')
      .then(res => {
        this.setState({car_brands: res.data})
        //alert (res.data)
      }).catch(error => {
      this.setState({car_brands: []})
    })
  }

  handleCarBrandChange (event) {
    let car_brand_id = event.target.value
    // var car_brand_name = event.target.options[event.target.selectedIndex].text
    this.loadCarModel(car_brand_id);
  }

  loadCarModel(car_brand_id){
    axios.get(APIURL + '/car_brand_model/' + car_brand_id)
      .then(res => {
        this.setState({
          car_brand_id: car_brand_id,
          car_models: res.data,
        })
      }).catch(error => {
      this.setState({car_models: []})
    })
  }

    render(){
        // const { history } = this.props
        const {
            form_controls,
            employment_types ,
            work_experiences ,
            salary_channels ,
            salary_receipt_types,
          car_type_interests,
            customer_grades,
          car_brands,
          car_models
        } = this.state

      let province_list = this.state.province.map((province, index) => {
        return (
          <option key={index+1} value={province.province_id}>{province.province_name}</option>
        )
      });

        let employee_list = this.state.employee.map((employee, index) => {
            return (
                <option key={index+1} value={employee.employee_id}>{employee.employee_name}</option>
            )
        });

        let employment_type_list = employment_types.map((obj, index) => {
            return (
                <option key={index+1} value={obj.employment_type}>{obj.employment_type}</option>
            )
        })

        let work_experience_list = work_experiences.map((obj, index) => {
            return (
                <option key={index+1} value={obj.work_experience}>{obj.work_experience}</option>
            )
        })

        let salary_channel_list = salary_channels.map((obj, index) => {
            return (
                <option key={index+1} value={obj.salary_channel}>{obj.salary_channel}</option>
            )
        })

        let salary_receipt_type_list = salary_receipt_types.map((obj, index) => {
            return (
                <option key={index+1} value={obj.salary_receipt_type}>{obj.salary_receipt_type}</option>
            )
        })

      let car_type_interests_list = car_type_interests.map((obj, index) => {
        return (
          <option key={index+1} value={obj.car_type_interest_id}>{obj.car_type_interest_name}</option>
        )
      });

        let customer_grades_list = customer_grades.map((obj, index) => {
            return (
                <option key={index+1} value={obj.customer_grade_id}>{obj.customer_grade_name}</option>
            )
        });

      let car_brand_list = car_brands.map((car_brand, index) => {
        return (
          <option key={index} value={car_brand.car_brand_id}>{car_brand.car_brand_name}</option>
        )
      });

      let car_brand_model = car_models.map((car_model, index) => {
        return (
          <option key={index} value={car_model.car_model_id}>{car_model.car_model_name}</option>
        )
      });

        let car_sell_type_list = this.state.car_sell_type.map((car_sell_type, index) => {
            return (
                <option key={index}
                        value={car_sell_type.car_sell_type_id}>{car_sell_type.car_sell_type_name}</option>
            )
        })

        let class_row_employee_admin = PORT === 9008 ?  "form-group col-sm-2" : "form-group col-sm-3";

        return(

            <div className="card card-accent-warning">

              <OccupationModal
                isModal={this.state.isOccupationModal}
                onSelectOccupation={this.onSelectOccupation}
                closeModal={() => {
                    //
                    // this.props.onCustomerSubmit.update
                  this.setState({
                    isOccupationModal: false,
                    occupation: null
                  })
                }}
              />

                <form action="" method="post"
                      onSubmit={this.updateCustomer} >

                    <div className="card-header card-edit">
                        <strong className="text-title">แก้ไขข้อมูลลูกค้า</strong>
                    </div>

                    <div className="card-block">
                        <div className="row">
                            <input type="hidden"
                                   className="form-control"
                                   id="customer_id"
                                   placeholder=""
                                   value={this.state.customer_id}
                                   onChange={this.onInputChangeCustomer}
                            />
                            <div className="form-group col-sm-3">
                                <label htmlFor="customer_name">ชื่อ</label>
                                <input type="text"
                                       className="form-control"
                                       id="customer_name"
                                       placeholder=""
                                       value={this.state.customer_name}
                                       onChange={this.onInputChangeCustomer}
                                />
                            </div>
                            <div className="form-group col-sm-3">
                                <label htmlFor="customer_lastname">นามสกุล</label>
                                <input type="text"
                                       className="form-control"
                                       id="customer_lastname"
                                       placeholder=""
                                       ref="customer_lastname"
                                       value={this.state.customer_lastname}
                                       onChange={this.onInputChangeCustomer}
                                />
                            </div>

                            <div className="form-group col-sm-3">
                                <label htmlFor="customer_card_id">เลขบัตรประชาชน</label>
                                <input type="text"
                                       className="form-control hidden"
                                       id="customer_card_id_old-bk"
                                       maxLength="13"
                                       placeholder=""
                                />

                                <MaskedInput
                                    mask="1-1111-11111-11-1"
                                    id="customer_card_id"
                                    name="customer_card_id"
                                    value={this.state.customer_card_id}
                                    maxLength="20"
                                    placeholder=""
                                    className="form-control"
                                    onChange={(e) => {
                                        let val = e.target.value
                                        // console.log('val=', val)
                                        this.setState({
                                            customer_card_id: val
                                        })
                                    }}
                                />
                            </div>

                            <div className="form-group col-sm-3">
                                <label htmlFor="customer_birth_text">วันเดือนปีเกิด (พ.ศ.)</label>
                                <MaskedInput
                                    mask="11-11-1111"
                                    name="customer_birth_text"
                                    placeholder="dd-mm-yyyy"
                                    onChange={this.onMaskedDateCustomerEdit1}
                                    className="form-control"
                                    value={this.state.customer_birth_mask}
                                />
                                <input className="form-control hidden"
                                       type="text"
                                       id="customer_birth"
                                       name="customer_birth"
                                       ref="customer_birth"
                                       value={this.state.customer_birth} onChange={()=>{}}
                                />

                            </div>

                            <div className="form-group col-sm-2">
                                <label htmlFor="customer_age">อายุ</label>
                                <input type="text"
                                       className="form-control"
                                       id="customer_age"
                                       placeholder=""
                                       ref="customer_age"
                                       value={this.state.customer_age}
                                       onChange={this.onInputChangeCustomer}
                                />
                            </div>

                            <div className="form-group col-sm-2">
                                <label htmlFor="customer_mobile">เบอร์โทรศัพท์(1) </label>
                                <MaskedInput
                                    mask="111-111-1111"
                                    id="customer_mobile"
                                    name="customer_mobile"
                                    ref="customer_mobile"
                                    placeholder=""
                                    className="form-control"
                                    value={this.state.customer_mobile}
                                    onChange={this.onInputChangeCustomer}
                                />
                            </div>

                            <div className="form-group col-sm-2">
                                <label htmlFor="customer_mobile">เบอร์โทรศัพท์(2)</label>
                                <MaskedInput
                                    mask="111-111-1111"
                                    id="customer_mobile2"
                                    name="customer_mobile2"
                                    value={this.state.customer_mobile2}
                                    placeholder=""
                                    className="form-control"
                                    onChange={this.handleOnChange}
                                />
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="customer_address">ที่อยู่ปัจจุบัน</label>
                                <input type="text"
                                       className="form-control"
                                       id="customer_address"
                                       name="customer_address"
                                       placeholder=""
                                       ref="customer_address"
                                       value={this.state.customer_address || ''}
                                       onChange={this.onInputChangeCustomer}
                                />
                            </div>

                            <div className={class_row_employee_admin}>
                                <label htmlFor="province">จังหวัด</label>
                                <select className="form-control"
                                        name="province_id"
                                        value={this.state.province_id}
                                        onChange={(e) => {
                                            this.setState({
                                                province_id: e.target.value
                                            })
                                        }}
                                >
                                    <option key={0} value={0}>ไม่ระบุ</option>
                                    {province_list}
                                </select>
                            </div>

                            <div className={class_row_employee_admin}>
                                <label htmlFor="customer_line">Line</label>
                                <input type="text"
                                       className="form-control"
                                       id="customer_line"
                                       placeholder=""
                                       ref="customer_line"
                                       value={this.state.customer_line}
                                       onChange={this.onInputChangeCustomer}
                                />
                            </div>

                            <div className={class_row_employee_admin}>
                                <label htmlFor="customer_facebook">Facebook</label>
                                <input type="text"
                                       className="form-control"
                                       id="customer_facebook"
                                       placeholder=""
                                       ref="customer_facebook"
                                       value={this.state.customer_facebook}
                                       onChange={this.onInputChangeCustomer}
                                />
                            </div>

                            <div className="form-group col-sm-3">
                                <label htmlFor="employee">เซลล์ผู้ดูแล</label>
                                <select className="form-control"
                                        name="employee_id"
                                        value={this.state.employee_id}
                                        onChange={(e) => {
                                            this.setState({
                                                employee_id: e.target.value
                                            })
                                        }}
                                >
                                    <option key={0} value={0}>ไม่ระบุ</option>
                                    {employee_list}
                                </select>
                            </div>

                            {
                                PORT === 9008 ?
                                    <div className="form-group col-sm-3">
                                        <label htmlFor="employee">จากแอดมิน</label>
                                        <SelectSearch
                                            options={this.state.employee_admins}
                                            value={this.state.employee_admin_id}
                                            search={true}
                                            name="employee_admin_id"
                                            placeholder="จากแอดมิน"
                                            onChange={(val) => {
                                                this.setState({
                                                    employee_admin_id: val,
                                                })
                                            }}
                                        />
                                    </div>
                                    : null
                            }

                          <div className="form-group col-sm-3">
                            <label htmlFor="receipt_date">อาชีพ</label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="occupation_id"
                                   name="occupation_id"
                                   placeholder=""
                                   value={this.state.occupation_id}
                                   disabled={false}
                                   onChange={() => {}}
                            />
                            <div className="form-group row">
                              <div className="col-md-12">
                                <div className="input-group">
                                  <input type="text"
                                         className="form-control"
                                         placeholder=""
                                         value={this.state.occupation_id !== 0 ? this.state.occupation_name : '' }
                                         disabled={true}
                                  />
                                  <span className="input-group-btn">
                                            <button className="btn btn-secondary"
                                                    name="agent_find"
                                                    type="button"
                                                    style={styles.buttonHeight}
                                                    onClick={() => {
                                                      this.setState({
                                                        isOccupationModal: true
                                                      })
                                                    }}
                                            >
                                                <i className="icon-magnifier"/>
                                            </button>

                                        </span>
                                </div>
                              </div>
                            </div>
                          </div>

                            <div className="form-group col-sm-3">
                                <label htmlFor="employee">ประเภทการจ้าง</label>
                                <select className="form-control"
                                        name="employment_type"
                                        value={this.state.employment_type}
                                        onChange={this.handleOnChange}
                                >
                                    <option key={0} value={''}>ไม่ระบุ</option>
                                    {employment_type_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-3">
                                <label htmlFor="employee">อายุงาน</label>
                                <select className="form-control"
                                        name="work_experience"
                                        value={this.state.work_experience}
                                        onChange={this.handleOnChange}
                                >
                                    <option key={0} value={''}>ไม่ระบุ</option>
                                    {work_experience_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-3">
                                <BDatePicker
                                    isDateFill={0}
                                    name={'date_oneyear_employment'}
                                    value={this.state.date_oneyear_employment}
                                    label={'วันที่อายุงานครบ 1 ปี'}
                                    onUpdateDate={(buddhist_date, christ_date) => {
                                        this.setState({
                                            date_oneyear_employment: christ_date
                                        })
                                    }}
                                />
                            </div>

                            <div className="form-group col-sm-3">
                                <label htmlFor="employee">ช่องทางรับเงินเดือน</label>
                                <select className="form-control"
                                        name="salary_channel"
                                        value={this.state.salary_channel}
                                        onChange={this.handleOnChange}
                                >
                                    <option key={0} value={''}>ไม่ระบุ</option>
                                    {salary_channel_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-3">
                                <label htmlFor="employee">ประเภทใบรับเงินเดือน</label>
                                <select className="form-control"
                                        name="salary_receipt_type"
                                        value={this.state.salary_receipt_type}
                                        onChange={this.handleOnChange}
                                >
                                    <option key={0} value={''}>ไม่ระบุ</option>
                                    {salary_receipt_type_list}
                                </select>
                            </div>


                          <div className="form-group col-sm-3">
                            <label htmlFor="cost">รายได้/เงินเดือน</label>
                            <input type="text"
                                   className="form-control hidden "
                                   name="customer_income"
                                   placeholder=""
                                   ref="customer_income"
                                   value={this.state.customer_income}
                                   onChange={() => {}}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="รายได้/เงินเดือน"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              value={this.state.customer_income}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({customer_income: value})
                              }}
                            />
                          </div>


                            <div
                                 className={checkFormControls('customer_date_book', form_controls) ? "form-group col-sm-3" : "form-group col-sm-3 hidden" }
                            >
                                <label htmlFor="customer_date_book_text">วันที่จอง ว/ด/ป (พ.ศ.)</label>
                                <MaskedInput
                                    mask="11-11-1111"
                                    name="customer_date_book_text"
                                    placeholder="dd-mm-yyyy"
                                    onChange={this.onMaskedDateCustomerEditDateBook}
                                    className="form-control"
                                    value={this.state.customer_date_book_mask}
                                />
                                <input className="form-control hidden"
                                       type="text"
                                       id="customer_date_book"
                                       name="customer_date_book"
                                       ref="customer_date_book"
                                       value={this.state.customer_date_book} onChange={()=>{}}
                                />
                            </div>

                            <div className="form-group col-sm-3">
                                <label htmlFor="car_sell_type">ประเภทการซื้อ (เงินสด/จัดไฟแนนซ์)</label>
                                <select
                                    className="form-control"
                                    id="customer_sell_type_id"
                                    name="customer_sell_type_id"
                                    value={this.state.customer_sell_type_id}
                                    onChange={(e) => {
                                        this.setState({customer_sell_type_id: e.target.value})
                                    }}
                                >
                                    <option value='0'> เลือกประเภทการซื้อ</option>
                                    {car_sell_type_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-3">
                                <label htmlFor="customer_grade_id">
                                    เกรดลูกค้า
                                </label>
                                <select
                                    className="form-control"
                                    id="customer_grade_id"
                                    name="customer_grade_id"
                                    value={this.state.customer_grade_id}
                                    onChange={(e) => {
                                        this.setState({customer_grade_id: e.target.value})
                                    }}
                                >
                                    <option value='0'> เลือกเกรดลูกค้า</option>
                                    {customer_grades_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-9">
                                <label htmlFor="customer_note">หมายเหตุ</label>
                                <input type="text"
                                       className="form-control"
                                       id="customer_note"
                                       placeholder=""
                                       ref="customer_note"
                                       value={this.state.customer_note || ''}
                                       onChange={this.onInputChangeCustomer}
                                />
                            </div>

                          <div className="form-group col-sm-6 hidden">
                            <label htmlFor="customer_car_want">รถที่ลูกค้าต้องการ</label>
                            <input type="text"
                                   className="form-control"
                                   id="customer_car_want"
                                   name="customer_car_want"
                                   placeholder=""
                                   value={this.state.customer_car_want || ''}
                                   onChange={this.onInputChangeCustomer}
                            />
                          </div>


                        </div>

                      <div className="row">
                        <div className="col-sm-12">
                          <hr />
                        </div>
                        <div className="col-sm-12">
                          <strong>ข้อมูลผู้ค้ำประกัน/บุคคลอ้างอิง</strong>
                        </div>

                        <div className="row col-sm-12" style={{marginTop: 20}}>
                          <div className="form-group col-sm-3">
                            <label htmlFor="guarantor_name">ชื่อ-นามสกุลผู้ค้ำ</label>
                            <input type="text"
                                   className="form-control"
                                   id="guarantor_name"
                                   placeholder=""
                                   ref="guarantor_name"
                                   value={this.state.guarantor_name || ''}
                                   onChange={this.onInputChangeCustomer}
                            />
                          </div>

                          <div className="form-group col-sm-3">
                            <label htmlFor="guarantor_mobile">เบอร์โทรผู้ค้ำ</label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="guarantor_mobile_old-bk"
                                   placeholder=""
                                   ref="guarantor_mobile_old-bk"
                              // value={this.state.guarantor_mobile || ''}
                              // onChange={this.onInputChangeCustomer}
                            />

                            <MaskedInput
                              mask="111-111-1111"
                              id="guarantor_mobile"
                              name="guarantor_mobile"
                              ref="guarantor_mobile"
                              placeholder=""
                              className="form-control"
                              value={this.state.guarantor_mobile || ''}
                              onChange={this.onInputChangeCustomer}
                            />
                          </div>

                          <div className="form-group col-sm-6">
                            <label htmlFor="guarantor_address">ที่อยู่ผู้ค้ำ</label>
                            <input type="text"
                                   className="form-control"
                                   id="guarantor_address"
                                   placeholder=""
                                   ref="guarantor_address"
                                   value={this.state.guarantor_address || ''}
                                   onChange={this.onInputChangeCustomer}
                            />
                          </div>

                        </div>

                      </div>

                      <div className="row">
                        <div className="col-sm-12">
                          <hr />
                        </div>
                        <div className="col-sm-12">
                          <strong>ข้อมูลรถที่ลูกค้าต้องการ</strong>
                        </div>

                        <div className="row col-sm-12" style={{marginTop: 20}}>
                          <div className="form-group col-sm-3">
                            <label htmlFor="car_type_interest_id">ประเภทรถที่สนใจ</label>
                            <select className="form-control"
                                    name="car_type_interest_id"
                                    value={this.state.car_type_interest_id}
                                    onChange={this.handleOnChange}
                            >
                              {car_type_interests_list}
                            </select>
                          </div>

                          <div className="form-group col-3">
                            <label htmlFor="car_brand_id">ยี่ห้อ</label>
                            <select className="form-control"
                                    id="car_brand_id"
                                    name="car_brand_id"
                                    onChange={this.handleCarBrandChange}
                                    value={this.state.car_brand_id || ''}
                            >
                              <option value="">เลือกยี่ห้อรถ</option>
                              {car_brand_list}
                            </select>
                          </div>

                          <div className="form-group col-3">
                            <label htmlFor="car_model_id">รุ่นรถ</label>
                            <select className="form-control"
                                    id="car_model_id"
                                    name="car_model_id"
                                    onChange={(e)=> {
                                      this.setState({
                                        car_model_id: e.target.value
                                      })
                                    }}
                                    value={this.state.car_model_id || ''}
                            >
                              <option value="">เลือกรุ่นรถ</option>
                              {car_brand_model}
                            </select>
                          </div>

                          <div className="form-group col-sm-3">
                            <label htmlFor="cost">ยอดผ่อนต่อเดือน</label>

                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="ยอดผ่อนต่อเดือน"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              value={this.state.installment_payment || ''}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({installment_payment: value})
                              }}
                            />
                          </div>

                        </div>
                      </div>

                    </div>
                    <div className="card-footer text-right">

                      <button type="reset"
                              onClick={this.props.onToggle}
                              className="btn btn-sm btn-danger mr-2">
                        <i className="fa fa-close"/> ปิดหน้านี้
                      </button>

                        <button type="submit"
                                className="btn btn-sm btn-warning">
                            <i className="fa fa-save"/> แก้ไข
                        </button>
                    </div>
                </form>
            </div>

        )
    }
}

const styles = {
  buttonHeight: {
      height: 36,
      paddingTop:0,
      paddingBottom:0,
      display: 'inline',
      marginTop: 0
  }
}
