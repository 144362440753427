import React, {Component} from "react";
import Loading from "../../Loading";
import DatePicker from "react-datepicker";
import axios from "axios";
import {APIURL, IMAGE_NOPHOTO_URL, IMAGE_UPLOAD_ERROR, PORT} from "../../../config/config";
import {AlertWarning} from "../../Alert/Alert";
import moment from "moment";
import parse from "html-react-parser";
import NumberFormat from "react-number-format";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import PreviewImage from "../../../views/PreviewImage";
import ReservationChangeCar from "./ReservationChangeCar";
import {connect} from "react-redux";
import { format } from 'date-fns'
import ThaiBaht from "thai-baht-text";
import {CarBookUtil} from "../../../utils/carBookUtil";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {getDocTypeConfig} from "../../../services/companysetting";
import {checkGroupPermission} from "../../../services/grouppermission";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew-Regular.ttf',
        bold: 'THSarabunNew-Bold.ttf',
        italics: 'THSarabunNew-Italic.ttf',
        bolditalics: 'THSarabunNew-BoldItalic.ttf'
    },
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    }
};

const port = Number(window.env.PORT);

class CarReservationOutstanding extends Component{
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(this.props.user),
            isLoading: false,
            date_start: new Date(),
            date_end: new Date(),
            branch_id: '',
            branches: [],
            car_book_id: '',
            car_book_list: [],
            preview_image_select: false,
            preview_image_url: '',
            slip_image1: IMAGE_NOPHOTO_URL,
            slip_image2: IMAGE_NOPHOTO_URL,
            slip_key1: '',
            slip_key2: '',
            car_sell_pay_selected: {},
            toggle_bill_print: false,
            book_number: '',
            volume_number: '',
            car_sell_pay_id: '',
            car_sell_pay_count: 0,
            car_book_refund: 0,
            book_comment_refund: null,
            toggle_change_car: false,
            keyword: '',
            company_setting: null,
            doc_type: '',
            doc_config: '',
            is_vat: 0,
            include_vat: 0,
            doc_no: '',
            car: '',
            car_id:'',
        }

        this.handleDateStart = this.handleDateStart.bind(this);
        this.handleDateEnd = this.handleDateEnd.bind(this);
        this.loadBranchData = this.loadBranchData.bind(this);
        this.loadCarBookOutstanding = this.loadCarBookOutstanding.bind(this);
        this.getCarBookStatusText = this.getCarBookStatusText.bind(this);
        this.getCarBookManage = this.getCarBookManage.bind(this);
        this.toggleSlip = this.toggleSlip.bind(this);
        this.togglePrintBill = this.togglePrintBill.bind(this);
        this.updateBookNumber = this.updateBookNumber.bind(this);
        this.saveBookNumber = this.saveBookNumber.bind(this);
        this.refundCarBook = this.refundCarBook.bind(this);
        this.toggleRefund = this.toggleRefund.bind(this);
        this.carBookCancelRefund = this.carBookCancelRefund.bind(this);

        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.saveCarImage = this.saveCarImage.bind(this);

        this.printOcpb = this.printOcpb.bind(this);
        this.loadCompanySetting = this.loadCompanySetting.bind(this);
        this.loadCompanyLogoBase64 = this.loadCompanyLogoBase64.bind(this);
        this.onPreviewSelected = this.onPreviewSelected.bind(this);
        this.togglePreviewImage = this.togglePreviewImage.bind(this);
    }

    togglePrintBill(){
        this.setState({
            toggle_bill_print: !this.state.toggle_bill_print
        })
    }

    loadCompanySetting () {
        axios.get(APIURL + '/company_setting/1')
            .then(res => {
                let data = res.data
                let company_setting = data.company_setting
                if (company_setting !== null) {
                    const is_vat = 0;
                    const doc_type = 'RE';
                    const doc_config = getDocTypeConfig(company_setting, doc_type);
                    const include_vat = 0;

                    this.setState({
                        company_setting: company_setting,
                        doc_type: doc_type,
                        doc_config: doc_config,
                        is_vat: is_vat,
                        include_vat: include_vat
                    })
                }
            })
    }

    loadCompanyLogoBase64() {
        let uri =  APIURL + '/company_setting/logoimage/1';

        axios.get(uri)
            .then(res => {
                let body = res.data.body
                this.setState({ logoBase64Img: body })
            }).catch(error => {
            console.log('error: ', error)
        })
    }

    /*loadCarById () {
        // var that = this
        axios.get(APIURL + '/car/' + this.state.car_id)
            .then(async res => {
                let car = res.data[0]
                this.setState({
                    car: car
                })
            }).catch(error => {
            // alert('error' + error)
            console.log('error:', error)
            this.setState({car: {}})
        })
    }*/

    toggleSlip(){
        this.setState({
            toggle_slip: !this.state.toggle_slip
        })
    }

    handleDateStart(date){
        this.setState({
            date_start: date
        })
    }

    handleDateEnd(date){
        this.setState({
            date_end: date
        })
    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branches: res.data})
            }).catch(error => {
            this.setState({branches: []})
        })
    }

    loadCarBookOutstanding() {
        if(this.state.date_start > this.state.date_end){
            AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
            return
        }

        let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
        let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')
        let branch_id = this.state.branch_id
        let keyword = this.state.keyword


        const data = {
            start_date,
            end_date,
            branch_id,
            keyword
        }

        this.setState({
            isLoading: true
        }, () => {

            let api_url = APIURL + '/car_book/outstanding'
            axios.post(api_url, data)
              .then(res => {
                  setTimeout(()=>{
                      this.setState({
                          isLoading: false
                      })
                  }, 1200)

                  this.setState({car_book_list: res.data})

              }).catch(error => {
                console.log('พอข้อผิดพลาด: ' + error.message)
                this.setState({car_book_list: []})
            })
        })
    }

    componentDidMount() {
        this.loadBranchData()
        let today = new Date()
        let start = format(today, 'yyyy-MM-01')
        let end = format(today, 'yyyy-MM-dd')

        this.setState({
            date_start: new Date(start),
            date_end : new Date(end)
        }, () => {
            this.loadCompanyLogoBase64();
            this.loadCompanySetting();
            this.loadCarBookOutstanding();
            //this.loadCarById();
            //this.loadCarBook ();
        })

    }

    getCarBookStatusText(car_book_status_id, car_sell_pay_tag, status_field, car_sell_pay) {
        let book_status = ''
        let badge = ''

        if (car_book_status_id === 1 && car_sell_pay_tag===null) {
            book_status = 'จอง'
            badge = 'badge badge-success'
        } else if (car_book_status_id === 1 && car_sell_pay_tag!==null) {
            book_status = car_sell_pay_tag
            badge = 'badge badge-primary'
        } else if (car_book_status_id === 2 && status_field === 'show'){
            if(car_sell_pay.car_sell_pay_tag === 'จ่ายเพิ่มเติม' && car_sell_pay.car_sell_pay_refund === 0){
                book_status = car_sell_pay_tag
                badge = 'badge badge-primary'
            }else{
                book_status = 'คืนเงินจอง'
                badge = 'badge badge-warning'
            }
        } else if (car_book_status_id === 2 && status_field === 'delete'){
            book_status = 'ยกเลิกการจอง'
            badge = 'badge badge-danger'
        }

        return <span className={badge}> {book_status}</span>

    }

    handleUploadFile (event){
        const car_book = this.state.car_sell_pay_selected
        const input_file_name = event.target.name
        let slip_key = ''

        const that = this;
        const car_book_id = car_book.car_book_id

        // input_file_name= slip_image2
        if (input_file_name === 'slip_image1'){
            slip_key = this.state.slip_key1 === null ? '' : this.state.slip_key1
            this.refs.img_slip_image1.src='img/loading.gif'
            this.refs.img_slip_image1.height=32
        } else if (input_file_name === 'slip_image2') {
            slip_key = this.state.slip_key2 === null ? '' : this.state.slip_key2
            this.refs.img_slip_image2.src='img/loading.gif'
            this.refs.img_slip_image2.height=32
        }

        const data = new FormData();
        data.append('uploads', event.target.files[0], event.target.files[0].name)
        data.append('car_book_id', car_book_id)
        data.append('slip_key', slip_key)
        data.append('slip_image', input_file_name)

        axios.put(APIURL+'/car_book/slip/'+car_book_id, data).then(function(res) {
            const resData = res.data;
            const filename = resData.image;
            const slip_key = resData.slip_key;
            let image_url = ''

            if(resData.image === undefined) {
                image_url = IMAGE_UPLOAD_ERROR
            } else if (resData.image === 'uploaderror') {
                image_url = IMAGE_UPLOAD_ERROR
            } else {
                image_url = filename
            }

            if( input_file_name === 'slip_image1'){
                that.setState({
                    slip_image1: image_url,
                    slip_key1: slip_key
                })
            } else if (input_file_name === 'slip_image2') {
                that.setState({
                    slip_image2: image_url,
                    slip_key2: slip_key
                })
            }

        })

    }

    saveCarImage(e,urlImage){
        e.preventDefault();
        let a = document.createElement('a');
        a.href = urlImage;
        a.target = '_blank';
        a.download = urlImage;
        a.click();
        a.remove();
        return false;
    }

    onPreviewSelected(url){
        this.setState({
            preview_image_url: url,
            preview_image_select: true
        });
    }

    togglePreviewImage() {
        this.setState({
            preview_image_select: !this.state.preview_image_select
        });
    }

    printOcpb (car_sell_pay) {
        const car_book_id = car_sell_pay.car_book_id;
        let car_brance = ''
        let car_brance_address = ''
        let car_brance_tel = ''
        let tax_id = this.state.company_setting.juristic_number

        let company_name = this.state.company_setting.company_name
        car_brance_address = this.state.company_setting.company_address
        car_brance_tel = this.state.company_setting.company_tel
        car_brance = company_name

        let car_brand_name = car_sell_pay.car_brand_name
        let car_model_name = car_sell_pay.car_model_name

        let car_name2 = car_brand_name + ' ' + car_model_name

        const car_name = car_sell_pay.car_name //ยี่ห้อรถ

        const car_color_name = car_sell_pay.car_color_name //สี
        const car_year_name = car_sell_pay.car_year_name //ปี
        const car_license_plate = car_sell_pay.car_license_plate_new !== '' &&  car_sell_pay.car_license_plate_new !==  car_sell_pay.car_license_plate_old ?
            car_sell_pay.car_license_plate_new : car_sell_pay.car_license_plate_old

        const province_name = car_sell_pay.car_license_plate_new !== '' &&  car_sell_pay.car_license_plate_new !==  car_sell_pay.car_license_plate_old ?
            car_sell_pay.province_name_new : car_sell_pay.province_name_old

        const customer_name = car_sell_pay.customer_name //ชื่อลูกค้า
        const customer_lastname = car_sell_pay.customer_lastname //นามสกุลลูกค้า
        const customer_address = car_sell_pay.customer_address //ที่อยู่ลูกค้า
        const customer_mobile = car_sell_pay.customer_mobile //
        const date_pay = moment(car_sell_pay.date_pay).locale('th').format('LL') //วันที่ออกใบเสร็จหรือวันที่จ่าย

        let employee_book = car_sell_pay.employee_book_id === 0 || car_sell_pay.employee_book_id === '' ? 'ไม่ระบุ' : car_sell_pay.employee_book_name + ' ' + car_sell_pay.employee_book_lastname
        let person_transaction = car_sell_pay.booking_by === null || car_sell_pay.booking_by === '' ? employee_book : car_sell_pay.booking_by //ผู้รับจอง
        const car_sell_list_pay = car_sell_pay.car_sell_list_pay_name //รายการที่จ่าย
        const sales_type = car_sell_pay.sales_type_name //รูปแบบการจ่าย

        const car_sell_pay_count = '฿' + car_sell_pay.car_sell_pay_count.toLocaleString()  //ยอดเงินจอง

        let bill_no = 'R'+car_book_id //this.refs.bill_no.value

        const book_number = car_sell_pay.book_number //
        const volume_number = car_sell_pay.volume_number === '' ? '-' : String(car_sell_pay.volume_number) //

        if (book_number !== '' && book_number !== null) {
            bill_no = book_number
        }

        let car_note =  ' '

        let car_book_status_id = car_sell_pay.car_book_status_id

        let moneyText = ThaiBaht(car_sell_pay.car_sell_pay_count)

        let car_sell_pay_refund = '฿' + car_sell_pay.car_sell_pay_refund.toLocaleString()  //ยอดคืนเงินจอง

        let billDetail = car_sell_list_pay + '  ' + car_name + '  '
            + 'สี' + car_color_name + '\n '
            + 'ทะเบียนรถ ' +  car_license_plate + ' ' + 'จังหวัด' + province_name +' \n'
            + 'การชำระเงิน  :  ' + sales_type + ' '
            + car_note

        if(PORT === 9048){
            billDetail = car_sell_list_pay + '  ' + car_name2 + '  '
                + 'ปี ' + car_year_name + ' '
                + 'สี' + car_color_name + '\n '
                + 'ทะเบียนรถ ' +  car_license_plate + ' ' + 'จังหวัด' + province_name +' \n'
                + 'การชำระเงิน  :  ' + sales_type + ' '
                + car_note
        }

        let printRefund = ''
        if(PORT === 9040 && car_book_status_id === 2){
            printRefund = [
                {text: '1', style: 'myCellDetail'},
                {text: billDetail, style: 'body'},
                {text: car_sell_pay_refund, style: 'myCellPrice'},
                {text: car_sell_pay_refund, style: 'myCellSumPrice'}
            ]
        }else{
            printRefund = [
                {text: '1', style: 'myCellDetail'},
                {text: billDetail, style: 'body'},
                {text: car_sell_pay_count, style: 'myCellPrice'},
                {text: car_sell_pay_count, style: 'myCellSumPrice'}
            ]
        }

        let pay_amount = ''
        if(PORT === 9040 && car_book_status_id === 2){
            pay_amount = [{text: 'รวมราคา', style: 'myCellSumText'}, {
                text: car_sell_pay_refund,
                style: 'myCellSumCost'
            }]
        }else{
            pay_amount = [{text: 'รวมราคา', style: 'myCellSumText'}, {
                text: car_sell_pay_count,
                style: 'myCellSumCost'
            }]
        }

        let pay_amount_total = ''
        if(PORT === 9040 && car_book_status_id === 2){
            pay_amount_total = [{
                text: 'รวมราคาทั้งสิ้น   '  + moneyText,
                style: 'myCellSumTextCost'
            }, {text: car_sell_pay_refund, style: 'myCellSumTextCostNum'}]
        }else{
            pay_amount_total = [{
                text: 'รวมราคาทั้งสิ้น   '  + moneyText,
                style: 'myCellSumTextCost'
            }, {text: car_sell_pay_count, style: 'myCellSumTextCostNum'}]
        }

        let businessCol = []
        //let businessCol2 = []
        if (tax_id !== '0000000000000' && tax_id !== null) {
            // แสดงเลขที่ผู้เสียภาษี
            businessCol = {
                columns: [
                    {
                        width: '15%',
                        text: 'ผู้ประกอบธุรกิจ :', style: 'bodycompany'
                    },
                    {
                        width: '45%', style: 'bodycompany',
                        text: car_brance ,
                    },
                    {
                        width: '15%',
                        text: 'เลขที่ผู้เสียภาษี :', style: 'bodycompany'
                    },
                    {
                        width: '25%', style: 'bodycompany',
                        text: tax_id,
                    },
                ],
                columnGap: 20,
                lineHeight: 1
            }
        } else {
            // ไม่แสดงเลขที่ผู้เสียภาษี
            businessCol = {
                columns: [
                    {
                        width: '15%',
                        text: 'ผู้ประกอบธุรกิจ :', style: 'bodycompany'
                    },
                    {
                        width: '85%', style: 'bodycompany',
                        text: car_brance,
                    },
                ],
                columnGap: 20,
                lineHeight: 1
            }
        }


        let businessCol2 = JSON.parse(JSON.stringify(businessCol));
        let printRefundCopy = JSON.parse(JSON.stringify(printRefund));
        let payAmountCopy = JSON.parse(JSON.stringify(pay_amount));
        let payAmountTotal = JSON.parse(JSON.stringify(pay_amount_total));

        // const carDetail2 = JSON.parse(JSON.stringify(carDetail));
        let bookBG = CarBookUtil.getBookBG(port, car_book_status_id)


        let doc_no = car_sell_pay.doc_no
        let doc_config = this.state.doc_config
        let colBillNumber = null

        if (doc_no !== null && doc_config === 'auto') {

            colBillNumber = {
                columns: [
                    {
                        width: '20%',
                        text: ' ', style: 'bodycompany'
                    },
                    {
                        width: '40%', style: 'bodycompany',
                        text: ' ',
                    },
                    {
                        width: '15%',
                        text: 'เลขที่ :', style: 'bodycompany'
                    },
                    {
                        width: '25%', style: 'bodycompany',
                        text: doc_no,
                    },
                ],
                columnGap: 20,
                lineHeight: 1
            }
        } else if (doc_no === null && (volume_number === null || volume_number === '')) {
            colBillNumber = {
                columns: [
                    {
                        width: '20%',
                        text: ' ', style: 'bodycompany'
                    },
                    {
                        width: '40%', style: 'bodycompany',
                        text: ' ',
                    },
                    {
                        width: '20%',
                        text: 'เลขที่ :', style: 'bodycompany'
                    },
                    {
                        width: '20%', style: 'bodycompany',
                        text: bill_no,
                    },
                ],
                columnGap: 20,
                lineHeight: 1
            }
        } else {
            colBillNumber = {
                columns: [
                    {
                        width: '20%',
                        text: ' ', style: 'bodycompany'
                    },
                    {
                        width: '46%', style: 'bodycompany',
                        text: ' ',
                    },
                    {
                        width: '5%',
                        text: 'เล่มที่ ', style: 'bodycompany'
                    },
                    {
                        width: '6%', style: 'bodycompany',
                        text: volume_number,
                    },
                    {
                        width: '5%',
                        text: 'เลขที่ ', style: 'bodycompany'
                    },
                    {
                        width: '18%', style: 'bodycompany',
                        text: bill_no,
                    },
                ],
                columnGap: 20,
                lineHeight: 1
            }
        }

        let colBillNumberCopy = JSON.parse(JSON.stringify(colBillNumber))

        let emptyRow1 = [
            {
                columns: [
                    {
                        width: '100%',
                        text: '.',
                        style: 'textWhite', margin: [0, 3, 0, 0],
                    },
                ],
                columnGap: 0,
                lineHeight:1,
            }
        ]
        let emptyRow2 = [
            {
                columns: [
                    {
                        width: '100%',
                        text: '.',
                        style: 'textWhite', margin: [0, 3, 0, 0],
                    },
                ],
                columnGap: 0,
                lineHeight:1,
            },
        ]

        let emptyRow = null
        if (PORT === 9043) {
            // atcarsale
            emptyRow = billDetail.length <= 128 ? emptyRow2 : emptyRow1;
        }

        let headRow = []
        if(PORT === 9008){
            headRow = {
                columns: [
                    {
                        width: '100%',
                        text: 'ใบเสร็จรับเงิน',
                        style: 'bodyhead', margin: [0, 70, 0, 0],
                    },
                ],
                columnGap: 0,
                lineHeight:1.3,
            }
        } else {
            headRow = {
                columns: [
                    {
                        width: '100%',
                        text: 'ใบเสร็จรับเงิน',
                        style: 'bodyhead', margin: [0, 80, 0, 0],
                    },
                ],
                columnGap: 0,
                lineHeight:1.3,
            }
        }

        const docDefinition = {
            pageOrientation: 'portrait',
            pageSize: 'A4',
            pageMargins: [25, 25, 25, 0],
            background: {image: 'book', width: 600},
            /*watermark: { text: 'คืนเงินมัดจำแล้ว', angle: -360, fontSize: 24, color: 'red', bold: true,},*/
            content: [
                {text: 'ใบเสร็จรับเงิน', style: 'bodyhead', lineHeight: 1.0, margin: [0, 0, 0, 10]},
                colBillNumber,
                {
                    columns: [
                        {
                            width: '20%',
                            text: '', style: 'bodycompany'
                        },
                        {
                            width: '40%', style: 'bodycompany',
                            text: '',
                        },
                        {
                            width: '15%',
                            text: 'วันที่ออกใบเสร็จ :', style: 'bodycompany'
                        },
                        {
                            width: '25%', style: 'bodycompany',
                            text: date_pay,
                        },
                    ],
                    columnGap: 20,
                    lineHeight: 1
                },
                businessCol,
                {
                    columns: [
                        {
                            width: '14%',
                            text: 'สถานที่ตั้ง :', style: 'bodycompany'
                        },
                        {
                            width: '86%', style: 'bodycompany',
                            text: car_brance_address,
                        },
                    ],
                    columnGap: 20,
                    lineHeight: 1
                },
                {
                    columns: [

                        {
                            width: '15%',
                            text: 'เบอร์โทรศัพท์ :', style: 'bodycompany'
                        },
                        {
                            width: '45%', style: 'bodycompany',
                            text: car_brance_tel,
                        },
                        {
                            width: '15%',
                            text: 'ผู้รับจอง(เซลล์) :', style: 'bodycompany'
                        },
                        {
                            width: '25%', style: 'bodycompany',
                            text: person_transaction,
                        },

                    ],
                    columnGap: 20,
                    lineHeight: 1.3
                },
                {
                    columns: [
                        {
                            width: '15%',
                            text: 'ชื่อ-สกุลลูกค้า :', style: 'bodycompany'
                        },
                        {
                            width: '45%', style: 'bodycompany',
                            text: customer_name + '   ' + customer_lastname,
                        },
                        {
                            width: '15%',
                            text: 'เบอร์โทรศัพท์ :', style: 'bodycompany'
                        },
                        {
                            width: '25%', style: 'bodycompany',
                            text: customer_mobile,
                        },
                    ],
                    columnGap: 20,
                    lineHeight: 1.0
                },
                {
                    columns: [
                        {
                            width: '14%',
                            text: 'ที่อยู่ :', style: 'bodycompany'
                        },
                        {
                            width: '86%', style: 'bodycompany',
                            text: customer_address,
                        },

                    ],

                    columnGap: 20,
                    lineHeight: 1.2
                },
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 2,
                        widths: [30, '*', 70, 80],
                        heights: [20, 20],

                        body: [
                            [{text: 'ลำดับ', style: 'myCell'},
                                {text: 'รายการ', style: 'myCell'},
                                {text: 'ราคา/หน่วย', style: 'myCell'},
                                {text: 'รวมราคา', style: 'myCell'}],
                            printRefund,
                        ]
                    },

                    columnGap: 20,
                },
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: ['*', 80],
                        heights: [20],

                        body: [
                            pay_amount,
                        ]
                    },

                    columnGap: 20,
                },
                {
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: ['*', 80],
                        heights: [20],

                        body: [
                            pay_amount_total,
                        ]
                    },

                    columnGap: 20,
                    lineHeight: 1
                },
                {
                    columns: [
                        {
                            width: '100%',
                            text: 'หมายเหตุ : หากยกเลิกหรือติดแบล็คลิสต์ไม่คืนมัดจำ',
                            style: 'textNote', margin: [0, 3, 0, 0],
                        },
                    ],
                    columnGap: 0,
                    lineHeight:1,
                },
                emptyRow,
                [
                    /*{text: 'ใบเสร็จรับเงิน', style: 'bodyhead', lineHeight: 1.3,  margin: [0, 80, 0, 0]},*/
                    headRow,
                    colBillNumberCopy,
                    /*{
                      columns: [
                        {
                          width: '20%',
                          text: '', style: 'bodycompany'
                        },
                        {
                          width: '46%', style: 'bodycompany',
                          text: '',
                        },
                        {
                          width: '5%',
                          text: 'เล่มที่', style: 'bodycompany'
                        },
                        {
                          width: '6%', style: 'bodycompany',
                          text: volume_number,
                        },
                        {
                          width: '5%',
                          text: 'เลขที่', style: 'bodycompany'
                        },
                        {
                          width: '18%', style: 'bodycompany',
                          text: bill_no,
                        },
                      ],

                      columnGap: 20,
                      lineHeight: 1
                    },*/
                    {
                        columns: [
                            {
                                width: '20%',
                                text: '', style: 'bodycompany'
                            },
                            {
                                width: '40%', style: 'bodycompany',
                                text: '',
                            },
                            {
                                width: '15%',
                                text: 'วันที่ออกใบเสร็จ :', style: 'bodycompany'
                            },
                            {
                                width: '25%', style: 'bodycompany',
                                text: date_pay,
                            },

                        ],
                        columnGap: 20,
                        lineHeight: 1
                    },
                    businessCol2,
                    {
                        columns: [
                            {
                                width: '14%',
                                text: 'สถานที่ตั้ง :', style: 'bodycompany'
                            },
                            {
                                width: '86%', style: 'bodycompany',
                                text: car_brance_address,
                            },

                        ],
                        columnGap: 20,
                        lineHeight: 1
                    },
                    {
                        columns: [

                            {
                                width: '15%',
                                text: 'เบอร์โทรศัพท์ :', style: 'bodycompany'
                            },
                            {
                                width: '45%', style: 'bodycompany',
                                text: car_brance_tel,
                            },
                            {
                                width: '15%',
                                text: 'ผู้รับจอง(เซลล์) :', style: 'bodycompany'
                            },
                            {
                                width: '25%', style: 'bodycompany',
                                text: person_transaction,
                            },

                        ],

                        columnGap: 20,
                        lineHeight: 1.3
                    },
                    {
                        columns: [
                            {
                                width: '15%',
                                text: 'ชื่อ-สกุลลูกค้า :', style: 'bodycompany'
                            },
                            {
                                width: '45%', style: 'bodycompany',
                                text: customer_name + '   ' + customer_lastname,
                            },
                            {
                                width: '15%',
                                text: 'เบอร์โทรศัพท์ :', style: 'bodycompany'
                            },
                            {
                                width: '25%', style: 'bodycompany',
                                text: customer_mobile,
                            },

                        ],

                        columnGap: 20,
                        lineHeight: 1.0
                    },
                    {
                        columns: [
                            {
                                width: '14%',
                                text: 'ที่อยู่ :', style: 'bodycompany'
                            },
                            {
                                width: '86%', style: 'bodycompany',
                                text: customer_address,
                            },

                        ],

                        columnGap: 20,
                        lineHeight: 1.2
                    },
                    {
                        table: {
                            // headers are automatically repeated if the table spans over multiple pages
                            // you can declare how many rows should be treated as headers
                            headerRows: 2,
                            widths: [30, '*', 70, 80],
                            heights: [20, 20],

                            body: [
                                [{text: 'ลำดับ', style: 'myCell'},
                                    {text: 'รายการ', style: 'myCell'},
                                    {text: 'ราคา/หน่วย', style: 'myCell'},
                                    {text: 'รวมราคา', style: 'myCell'}],
                                printRefundCopy,
                            ]
                        },

                        columnGap: 20,
                    },
                    {
                        table: {
                            // headers are automatically repeated if the table spans over multiple pages
                            // you can declare how many rows should be treated as headers
                            headerRows: 1,
                            widths: ['*', 80],
                            heights: [20],

                            body: [
                                payAmountCopy,
                            ]
                        },

                        columnGap: 20,
                    },
                    {
                        table: {
                            // headers are automatically repeated if the table spans over multiple pages
                            // you can declare how many rows should be treated as headers
                            headerRows: 1,
                            widths: ['*', 80],
                            heights: [20],
                            body: [
                                payAmountTotal,
                            ]
                        },

                        columnGap: 20,
                        lineHeight: 1
                    },
                    {
                        columns: [
                            {
                                width: '100%',
                                text: 'หมายเหตุ : หากยกเลิกหรือติดแบล็คลิสต์ไม่คืนมัดจำ',
                                style: 'textNote', margin: [0, 3, 0, 0]
                            },
                        ],
                        columnGap: 0,
                        lineHeight:1,
                    },
                ],
            ],

            images: {
                book: bookBG
            },

            styles: {
                bodyhead: {
                    fontSize: 18,
                    bold: true,
                    alignment: 'center'
                },
                body: {
                    fontSize: 14,
                },
                bodycompany: {
                    fontSize: 14,
                    bold: true,
                },
                anotherStyle: {
                    italic: true,
                },
                myCell: {
                    fontSize: 14,
                    fillColor: '#BEBEBE',
                    alignment: 'center',
                    bold: true,
                },
                myCellDetail: {
                    fontSize: 14,
                    alignment: 'center',
                },
                myCellPrice: {
                    fontSize: 14,
                    alignment: 'right',
                },
                myCellSumPrice: {
                    fontSize: 14,
                    alignment: 'right',
                    bold: true,
                },
                myCellSumText: {
                    fontSize: 14,
                    fillColor: '#D3D3D3',
                    alignment: 'left',
                    bold: true,
                },
                myCellSumCost: {
                    fontSize: 14,
                    fillColor: '#D3D3D3',
                    alignment: 'right',
                    bold: true,
                },
                myCellSumTextCost: {
                    fontSize: 14,
                    fillColor: '#FF9933',
                    alignment: 'left',
                    bold: true,
                },
                myCellSumTextCostNum: {
                    fontSize: 14,
                    fillColor: '#FF9933',
                    alignment: 'right',
                    bold: true,
                },
                textNote: {
                    fontSize: 14,
                    color : '#ff0000'
                },
                textWhite: {
                    fontSize: 14,
                    color : '#ffffff'
                },
            },

            defaultStyle: {
                font: 'THSarabunNew'
            }
        }
        pdfMake.createPdf(docDefinition).open()
    }

    carBookCancelRefund () {
        let book_rows = 0
        let car_id = this.state.car_sell_pay_selected.car_id
        let car_sell_pay_id = this.state.car_sell_pay_id
        let car_sell_pay_count = Number(this.state.car_sell_pay_count)
        let car_book_id = this.state.car_sell_pay_selected.car_book_id
        let car_book_refund = this.state.car_book_refund === '' ? 0 : Number(this.state.car_book_refund)
        //at ขอเพิ่มหมายเหตุการคืนเงินจอง
        let book_comment_refund = this.state.book_comment_refund

        // eslint-disable-next-line
        this.state.car_book_list.map((car_sell_pay, index) => {
            if (car_sell_pay.car_book_status_id === 1 && car_sell_pay.car_id === car_id) {
                book_rows++
            }
        })

        // alert(car_book_refund)

        if (car_book_refund === 0) {
            AlertWarning('กรุณากรอกยอดคืนเงิน')
            return
        }

        if (car_book_refund > car_sell_pay_count) {
            AlertWarning('ยอดเงินคืนมากกว่ายอดชำระ กรุณาตรวจสอบ')
            return
        }

        let data = {
            book_rows: book_rows,
            car_id: car_id,
            car_book_id: car_book_id,
            car_sell_pay_id: car_sell_pay_id,
            car_sell_pay_count: car_sell_pay_count,
            car_book_refund: car_book_refund,
            book_comment_refund: book_comment_refund
        }

        axios.put(APIURL + '/car_book/cancel/refund_after_sell/' + car_book_id, data).then(res => {
            if (res.status === 200) {
                this.loadCarBookOutstanding()
                this.toggleRefund()
                //this.setState({action: "บันทึก"});
            }
        }).catch(error => {
            alert('พอข้อผิดพลาด: ' + error.message)
        })
    }

    getCarBookManage(car_book_status_id, car_sell_pay_tag, status_field, car_sell_pay) {
        let book_status = ''
        if (car_book_status_id === 1 && car_sell_pay_tag===null) {
            book_status = 'จอง'
            return <td className='text-center'>

                <button onClick={() => {
                    // TODO:: แนบสลิป
                    this.setState({
                        car_sell_pay_selected: car_sell_pay,
                        slip_image1: car_sell_pay.slip_image1 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image1,
                        slip_image2: car_sell_pay.slip_image2 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image2,
                        slip_key1: car_sell_pay.slip_key1,
                        slip_key2: car_sell_pay.slip_key2,
                    },() => {
                        this.toggleSlip()
                    })
                }}
                        type="button" className="btn btn-success btn-sm btn-block">
                    <i className="icon-paper-clip">&nbsp;แนบสลิป</i>
                </button>


                {
                    (PORT === 9021 && this.state.user.type === 'sale' ) || (PORT === 9025 && this.state.user.type === 'sale') || (PORT === 9053 && this.state.user.type === 'sale') ? null : (
                        <>
                            <button onClick={() => {
                                this.refundCarBook(car_sell_pay)
                            }}
                                    type="button" className="btn btn-warning btn-sm btn-block">
                                <i className="icon-wallet">&nbsp;คืนเงินจอง</i>
                            </button>

                            <button onClick={() => {
                                this.changeCarBook(car_sell_pay)
                            }}
                                    type="button" className="btn btn-danger btn-sm btn-block"
                             style={{backgroundColor: '#F57B39'}}
                            >
                                <i className="icon-control-rewind">&nbsp; เปลี่ยนคัน </i>
                            </button>

                        </>
                    )
                }

                {
                    checkGroupPermission(701, this.props.grouppermissions).printed ? (
                        <button
                            type="button"
                            className="btn btn-success btn-sm btn-block"
                            onClick={() => {
                                // TODO:: กรอกเลขใบเสร็จก่อนพิมพ์
                                let doc_config = this.state.doc_config
                                if (doc_config === 'manual') {
                                    let book_number = car_sell_pay.book_number === null ? '' : car_sell_pay.book_number
                                    let volume_number = car_sell_pay.volume_number === null ? '' : car_sell_pay.volume_number
                                    this.setState({
                                        car_sell_pay_selected: car_sell_pay,
                                        book_number: book_number,
                                        volume_number: volume_number
                                    },() => {
                                        this.togglePrintBill()
                                    })
                                } else {
                                    this.printOcpb(car_sell_pay);
                                }
                            }}
                        >
                            <i className="icon-printer">&nbsp;พิมพ์ใบเสร็จ</i>
                        </button>
                    ) : null
                }

            </td>
        } else if (car_book_status_id === 1 && car_sell_pay_tag!==null) {
            book_status = car_sell_pay_tag
            return <td className='text-center'>
                <button onClick={() => {
                    //TODO:: กรอกเลขใบเสร็จก่อนพิมพ์
                    // this.setState({car_sell_pay_selected: car_sell_pay},() => {
                    // this.togglePrintBill()
                    // })
                    this.printOcpb(car_sell_pay);
                }}
                        type="button" className="btn btn-success btn-sm btn-block">
                    <i className="icon-printer">&nbsp;พิมพ์ใบเสร็จ</i>
                </button>

                <button onClick={() => {
                    this.deleteCarBookPayAddition(car_sell_pay)
                }}
                        type="button" className="btn btn-danger btn-sm btn-block">
                    <i className="icon-trash">&nbsp;ลบรายการ</i>
                </button>
            </td>
        } else if (car_book_status_id === 2 && status_field === 'show'){

            if(car_sell_pay_tag==='จ่ายเพิ่มเติม' && car_sell_pay.car_sell_pay_refund === 0) {
                book_status = 'จ่ายเพิ่มเติม'
                return <td className='text-center'>
                    <button onClick={() => {
                        // TODO:: แนบสลิป
                        this.setState({
                            car_sell_pay_selected: car_sell_pay,
                            slip_image1: car_sell_pay.slip_image1 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image1,
                            slip_image2: car_sell_pay.slip_image2 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image2,
                            slip_key1: car_sell_pay.slip_key1,
                            slip_key2: car_sell_pay.slip_key2,
                        },() => {
                            this.toggleSlip()
                        })
                    }}
                            type="button" className="btn btn-success btn-sm btn-block">
                        <i className="icon-wallet">&nbsp;แนบสลิป</i>
                    </button>

                    <button onClick={() => {
                        this.refundCarBook(car_sell_pay)
                    }}
                            type="button" className="btn btn-warning btn-sm btn-block">
                        <i className="icon-wallet">&nbsp;คืนเงินจอง</i>
                    </button>

                </td>
            }else{
                book_status = 'คืนเงินจอง'
                if(PORT === 9040 && car_book_status_id === 2){
                    return <td className='text-center'>
                        <button onClick={() => {
                            // TODO:: แนบสลิป
                            this.setState({
                                car_sell_pay_selected: car_sell_pay,
                                slip_image1: car_sell_pay.slip_image1 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image1,
                                slip_image2: car_sell_pay.slip_image2 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image2,
                                slip_key1: car_sell_pay.slip_key1,
                                slip_key2: car_sell_pay.slip_key2,
                            },() => {
                                this.toggleSlip()
                            })
                        }}
                                type="button" className="btn btn-success btn-sm btn-block">
                            <i className="icon-wallet">&nbsp;แนบสลิป</i>
                        </button>

                        <button onClick={() => {
                            // TODO:: กรอกเลขใบเสร็จก่อนพิมพ์
                            let book_number = car_sell_pay.book_number === null ? '' : car_sell_pay.book_number
                            let volume_number = car_sell_pay.volume_number === null ? '' : car_sell_pay.volume_number
                            this.setState({
                                car_sell_pay_selected: car_sell_pay,
                                book_number: book_number,
                                volume_number: volume_number
                            },() => {
                                this.togglePrintBill()
                            })

                            //
                            // this.printOcpb(car_sell_pay);
                        }}
                                type="button" className="btn btn-success btn-sm btn-block">
                            <i className="icon-printer">&nbsp;พิมพ์ใบเสร็จ</i>
                        </button>
                    </td>

                } else {
                    return <td className='text-center'>
                        <button onClick={() => {
                            // TODO:: แนบสลิป
                            this.setState({
                                car_sell_pay_selected: car_sell_pay,
                                slip_image1: car_sell_pay.slip_image1 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image1,
                                slip_image2: car_sell_pay.slip_image2 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image2,
                                slip_key1: car_sell_pay.slip_key1,
                                slip_key2: car_sell_pay.slip_key2,
                            },() => {
                                this.toggleSlip()
                            })
                        }}
                                type="button" className="btn btn-success btn-sm btn-block">
                            <i className="icon-wallet">&nbsp;แนบสลิป</i>
                        </button>
                    </td>
                }
            }


        } else if (car_book_status_id === 2 && status_field === 'delete'){
            book_status = 'ยกเลิกการจอง'
            return <td className='text-center'>
                <i className="icon-close"/>
            </td>
        }


    }

    refundCarBook (car_sell_pay) {
        //alert(car_sell_pay.car_book_id )

        this.setState({
            car_sell_pay_id: car_sell_pay.car_sell_pay_id,
            car_sell_pay_count: car_sell_pay.car_sell_pay_count,
            car_sell_pay_selected: car_sell_pay
        }, () => {
            this.toggleRefund()
        })

    }

    toggleRefund () {
        this.setState({
            refund: !this.state.refund
        })
    }

    saveBookNumber() {
        let book_number = this.state.book_number
        let volume_number = this.state.volume_number
        let car_sell_pay = this.state.car_sell_pay_selected
        let car_book_id = car_sell_pay.car_book_id
        let car_sell_pay_id = car_sell_pay.car_sell_pay_id
        let doc_no = car_sell_pay.doc_no

        // include_vat , vat , vat_bath, doc_type , doc_no

        if(car_sell_pay.book_number === null && car_sell_pay.volume_number === null ) {
            // update จาก null
            if (book_number !=='' || volume_number !== '') {
                doc_no = book_number
                this.updateBookNumber(car_book_id, book_number, volume_number, car_sell_pay_id);
            }
        } else if (car_sell_pay.book_number !== book_number || car_sell_pay.volume_number !== volume_number) {
            // มีการ เปลี่ยนค่า
            if (book_number !=='' || volume_number !== '') {
                doc_no = book_number
                this.updateBookNumber(car_book_id, book_number, volume_number, car_sell_pay_id);
            }
        }

        car_sell_pay = {
            ...car_sell_pay,
            book_number: book_number,
            volume_number: volume_number,
            doc_no: doc_no
        }

        this.printOcpb(car_sell_pay)
        this.togglePrintBill()
    }

    updateBookNumber(car_book_id, book_number, volume_number, car_sell_pay_id) {

        let doc_config = this.state.doc_config
        // car_book/book_number

        let data = {
            car_book_id: car_book_id,
            book_number: book_number,
            volume_number: volume_number,
            car_sell_pay_id: car_sell_pay_id,
            doc_config: doc_config
        }

        let api = APIURL+ '/car_book/book_number'
        axios.put(api, data).then(res => {
            //this.loadCarBook();
            this.loadCarBookOutstanding();
        }).catch(err => {
            console.log('error:', err)
        })
    }

    changeCarBook(car_sell_pay) {
        this.setState({
            car_sell_pay_selected: car_sell_pay,
            toggle_change_car: true
        })
    }

    render() {

        let car_branch_list = this.state.branches.map((branch, index) => {
            return (
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        })

        let car_sell_pay_tr = this.state.car_book_list.map((car_sell_pay, index) => {

            let car_book_status_id = car_sell_pay.car_book_status_id
            let car_sell_pay_refund = car_sell_pay.car_sell_pay_refund
            let car_sell_pay_tag = car_sell_pay.car_sell_pay_tag
            let car_book_id = car_sell_pay.car_book_id
            let book_number = car_sell_pay.book_number === '' ? 'R'+car_book_id : car_sell_pay.book_number
            let volume_number = car_sell_pay.volume_number
            let book_comment_refund = car_sell_pay.book_comment_refund === '' || car_sell_pay.book_comment_refund === null ? '-' : car_sell_pay.book_comment_refund

            let car_license_plate = car_sell_pay.car_license_plate_new === '' ?  car_sell_pay.car_license_plate_old : car_sell_pay.car_license_plate_new
            let province_name = car_sell_pay.car_license_plate_new === '' ?  car_sell_pay.province_name_old : car_sell_pay.province_name_new
            let car_name = car_sell_pay.car_name


            return (
                <tr key={index}>
                    <td
                        className="text-left"
                    >

                        {
                            book_number === null ? <span className="text-danger">เลขการจอง: {car_book_id}</span> : (
                                <>
                                    <span className="text-danger">เล่มที่ : {volume_number}</span> <br/>
                                    <span className="text-success">เลขที่ใบจอง : {book_number}</span>
                                </>
                            )
                        }
                        <br/>
                        วันที่จอง : {moment(car_sell_pay.date_pay).locale('th').format('LL')}


                    </td>
                    <td className="text-center">

                        {car_name} <br/>
                        ทะเบียน : {car_license_plate} {' '} {province_name}
                        <br/>
                        ลูกค้า : {car_sell_pay.customer_name}&nbsp;&nbsp;{car_sell_pay.customer_lastname}<br/>

                        {this.getCarBookStatusText(car_sell_pay.car_book_status_id, car_sell_pay_tag, car_sell_pay.status_field,car_sell_pay )}

                        {
                            car_sell_pay.book_comment === null ? null : (
                                <>
                                    <hr/>
                                    หมายเหตุการจอง :
                                    <br/>
                                </>
                            )
                        }
                        {
                            car_sell_pay.book_comment === null ? null : parse(car_sell_pay.book_comment)
                        }

                        <hr/>
                        {
                            car_sell_pay.booking_by === null ? null : (
                                <>
                                    ผู้รับจอง : {car_sell_pay.booking_by === null ? null : car_sell_pay.booking_by}
                                </>
                            )
                        }



                    </td>
                    <td className="text-center">
                        {car_sell_pay.car_sell_list_pay_name}

                        {
                            car_sell_pay.slip_image1 === null ? null : (
                                <>
                                    <br/>
                                    <br/>
                                    <label htmlFor="name">
                                        <a rel="noopener noreferrer"
                                           href={car_sell_pay.slip_image1}
                                           target="_blank"
                                           onClick={(e)=>this.saveCarImage(e, car_sell_pay.slip_image1)}
                                           className={car_sell_pay.slip_image1===null? 'hidden' : ''}>
                                            <span>Download</span>
                                        </a>
                                    </label>
                                    <br/>
                                    <img
                                        src={car_sell_pay.slip_image1}
                                        style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                                        onClick={()=>this.onPreviewSelected(car_sell_pay.slip_image1)}
                                        alt="เอกสาร 1"
                                    />
                                </>
                            )
                        }

                    </td>
                    <td className="text-center">
                        <NumberFormat value={car_sell_pay.car_sell_pay_count}
                                      displayType={'text'}
                                      thousandSeparator={true} prefix={'฿'}
                        />

                        {/*{car_book_status_id === 1 && car_sell_pay_tag===null ? '/คืนเงิน ': '' }*/}

                        {/*{car_book_status_id === 1 && car_sell_pay_tag===null ?  <NumberFormat value={car_sell_pay_refund} displayType={'text'} thousandSeparator={true} prefix={'฿'}/> : '' }*/}

                        {
                            car_sell_pay_refund !== 0 ? '/คืนเงิน ': null
                        }
                        {
                            car_sell_pay_refund !== 0 ? <NumberFormat value={car_sell_pay_refund} displayType={'text'} thousandSeparator={true} prefix={'฿'}/> : null
                        }

                        <hr/>
                        {

                            car_sell_pay_refund !== 0 ? 'หมายเหตุคืนเงินจอง : ' + book_comment_refund : null

                        }


                        {
                            car_sell_pay.slip_image2 === null ? null : (
                                <>
                                    <br/>
                                    <br/>
                                    <label htmlFor="name">
                                        <a rel="noopener noreferrer"
                                           href={car_sell_pay.slip_image2}
                                           target="_blank"
                                           onClick={(e)=>this.saveCarImage(e, car_sell_pay.slip_image2)}
                                           className={car_sell_pay.slip_image2===null? 'hidden' : ''}>
                                            <span>Download</span>
                                        </a>
                                    </label>
                                    <br/>
                                    <img
                                        src={car_sell_pay.slip_image2}
                                        style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                                        onClick={()=>this.onPreviewSelected(car_sell_pay.slip_image2)}
                                        alt="เอกสาร 2"
                                    />
                                </>
                            )
                        }

                    </td>
                    <td className="text-center">{car_sell_pay.sales_type_name}</td>
                    <td className="text-center">
                        {car_sell_pay.bank_name}
                    </td>
                    <td className="text-center">
                        {/*{car_sell_pay.person_transaction}*/}
                        {car_sell_pay.book_by} <br/>
                        เมื่อ: {moment(car_sell_pay.book_date).locale('th').format('LL')}

                    </td>

                    {this.getCarBookManage(car_sell_pay.car_book_status_id, car_sell_pay_tag, car_sell_pay.status_field, car_sell_pay)}


                </tr>
            )
        });

        return (
            <>
                <Loading isLoading={this.state.isLoading} />

                <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
                    <ModalBody>
                        <PreviewImage
                            imageUrl={this.state.preview_image_url}
                        />
                    </ModalBody>
                </Modal>

                <Modal className="modal-lg modal-dialog" isOpen={this.state.toggle_slip} toggle={this.toggleSlip}>
                    <ModalHeader toggle={this.toggleSlip.bind(this)}><strong className="font-sm">แนบสลิป</strong></ModalHeader>
                    <ModalBody>
                        <div className="row p-5">
                            <div className="form-group col-sm-1">
                            </div>

                            <div className="form-group col-sm-5">
                                <label htmlFor="name">1) แนบภาพสลิป 1
                                    &nbsp;
                                    <a rel="noopener noreferrer"
                                       href={this.state.slip_image1}
                                       target="_blank"
                                       onClick={(e)=>this.saveCarImage(e,this.state.slip_image1)}
                                       className={this.state.slip_image1===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                                        <span>Download</span>
                                    </a>
                                </label>
                                <div style={styles.fileInputWrapper} >

                                    <button style={styles.btnFileInput}>
                                        <img src={this.state.slip_image1}
                                             style={styles.btnFileInputImage}
                                             ref="img_slip_image1"
                                             alt="เอกสาร 1"
                                        />
                                    </button>
                                    <input
                                        style={styles.fileInput}
                                        type="file"
                                        name="slip_image1"
                                        onChange={this.handleUploadFile}
                                    />
                                </div>

                            </div>

                            <div className="form-group col-sm-5">
                                <label htmlFor="name">2) แนบภาพสลิป 2
                                    &nbsp;
                                    <a rel="noopener noreferrer"
                                       href={this.state.slip_image2}
                                       target="_blank"
                                       onClick={(e)=>this.saveCarImage(e,this.state.slip_image2)}
                                       className={this.state.slip_image2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                                        <span>Download</span>
                                    </a>
                                </label>
                                <div style={styles.fileInputWrapper} >
                                    <button style={styles.btnFileInput}>
                                        <img src={this.state.slip_image2}
                                             style={styles.btnFileInputImage}
                                             ref="img_slip_image2"
                                             alt="เอกสาร 2"
                                        />
                                    </button>
                                    <input
                                        style={styles.fileInput}
                                        type="file"
                                        name="slip_image2"
                                        onChange={this.handleUploadFile}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-sm-1">
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {/*<Button color="primary" onClick={()=>{
            }}>
               xxx
            </Button>*/}
                        {' '}
                        <Button color="secondary" onClick={() => {
                            // TODO: reload car_book
                            this.loadCarBookOutstanding();
                            this.toggleSlip();

                        }}>ยกเลิก</Button>
                    </ModalFooter>
                </Modal>


                <Modal className="modal-lg modal-dialog" isOpen={this.state.toggle_bill_print} toggle={this.togglePrintBill}>
                    <ModalBody>
                        <div id="divToPrint"  >
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <strong>เพิ่มข้อมูลใบจอง</strong>
                                </div>
                            </div>

                            <div className="row pt-5">

                                <div className="form-group col-sm-2">
                                </div>
                                <div className="form-group col-sm-3">
                                    <strong>เล่มที่ :</strong>
                                </div>
                                <div className="form-group col-sm-6">
                                    <input type="text"
                                           name="volume_number"
                                           maxLength={10}
                                           value={this.state.volume_number}
                                           onChange={(e) => {
                                               this.setState({
                                                   volume_number: e.target.value
                                               })
                                           }}
                                    />
                                </div>
                            </div>

                            <div className="row">

                                <div className="form-group col-sm-2">
                                </div>
                                <div className="form-group col-sm-3">
                                    <strong>เลขที่ใบเสร็จ :</strong>
                                </div>
                                <div className="form-group col-sm-6">
                                    <input type="text"
                                           name="book_number"
                                           maxLength={20}
                                           value={this.state.book_number}
                                           onChange={(e) => {
                                               this.setState({
                                                   book_number: e.target.value
                                               })
                                           }}
                                    />
                                </div>
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={()=> this.saveBookNumber()} >พิมพ์ใบเสร็จ</Button>{' '}
                        <Button color="secondary" onClick={this.togglePrintBill}>ยกเลิก</Button>
                    </ModalFooter>
                </Modal>


                <Modal isOpen={this.state.refund} toggle={this.toggleRefund.bind(this)}
                       className={'modal-lg modal-danger'}>
                    <ModalHeader toggle={this.toggleRefund.bind(this)}>คืนเงินจอง</ModalHeader>
                    <ModalBody>
                        <div className="card-block">
                            <div className="row">
                                <div className="row p-5">
                                    <div className="form-group col-sm-4">
                                        <strong>ยอดเงินจองที่ชำระ :</strong>
                                    </div>
                                    <div className="form-group col-sm-8">
                                        <input type="text"
                                               className="form-control hidden"
                                               ref="car_sell_pay_count"
                                               value={this.state.car_sell_pay_count}
                                               onChange={() => {}}
                                        />

                                        <NumberFormat
                                            className="form-control"
                                            thousandSeparator={true}
                                            prefix={'฿'}
                                            min={0}
                                            max={1000000000}
                                            step={1000}
                                            size={10}
                                            allowNegative={true}
                                            value={this.state.car_sell_pay_count}
                                            displayType="text"
                                            //onValueChange={() => {}}
                                        />
                                    </div>

                                    <div className="form-group col-sm-4">
                                        <strong>ยอดคืนเงิน :</strong>
                                    </div>

                                    <div className="form-group col-sm-8">
                                        <input type="text"
                                               className="form-control hidden"
                                               name="car_book_refund"
                                               placeholder=""
                                               ref="car_book_refund"
                                               value={this.state.car_book_refund}
                                               onChange={() => {}}
                                        />
                                        <NumberFormat
                                            className="form-control"
                                            thousandSeparator={true}
                                            prefix={'฿'}
                                            placeholder="คืนเงิน"
                                            min={0}
                                            max={1000000000}
                                            step={1000}
                                            size={10}
                                            allowNegative={false}
                                            onValueChange={(values) => {
                                                const {value} = values;
                                                this.setState({car_book_refund: value});
                                            }}
                                        />
                                    </div>

                                    <div className="form-group col-sm-4">
                                        <strong>หมายเหตุการคืนเงินจอง :</strong>
                                    </div>

                                    <div className="form-group col-sm-8">
                                        <input type="text"
                                               className="form-control"
                                               id="book_comment_refund"
                                               name="book_comment_refund"
                                            //value={this.state.book_comment_refund || ''}
                                               onChange={(e)=>{
                                                   this.setState({
                                                       book_comment_refund: e.target.value
                                                   })
                                               }}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.toggleRefund.bind(this)}>ยกเลิก</Button>
                        <Button color="primary"
                                onClick={this.carBookCancelRefund.bind(this)}>ตกลง</Button>{' '}

                    </ModalFooter>
                </Modal>

                {
                    this.state.toggle_change_car &&
                    <ReservationChangeCar
                        isOpen={this.state.toggle_change_car}
                        car_sell_pay={this.state.car_sell_pay_selected}
                        onToggle={() => {
                            this.setState({
                                toggle_change_car: !this.state.toggle_change_car
                            })
                        }}
                        onReload={() => this.loadCarBookOutstanding()}
                    />
                }

                <div className="row">
                    <div className="col-12">
                        <div className="card card-accent-info">
                            <div className="card-header">
                                <strong className="text-title"> การจองคงค้าง(รถขายแล้ว) </strong>
                            </div>

                            <div className="card-block">
                                <div className="row">
                                    <div className="mt-1 ml-3">
                                        จาก
                                    </div>

                                    <div className="form-group ml-2" style={{width: 150}} >
                                        <DatePicker
                                            selected={this.state.date_start}
                                            onChange={this.handleDateStart}
                                            dateFormat="yyyy-MM-dd"
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>

                                    <div className="form-group ml-2" style={{width: 150}}>
                                        <DatePicker
                                            selected={this.state.date_end}
                                            onChange={this.handleDateEnd}
                                            dateFormat="yyyy-MM-dd"
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>

                                    <div className="form-group ml-2 mr-2" style={{width: 180}}>
                                        <select className="form-control"
                                                id="branch_id"
                                                name="branch_id"
                                                onChange={(e) => {
                                                    this.setState({
                                                        branch_id: e.target.value
                                                    })
                                                }}
                                        >
                                            <option value=''> ตามสาขา </option>
                                            {car_branch_list}
                                        </select>
                                    </div>

                                    <div className="input-group" style={{width: '230px'}}>
                                        <input type="text"
                                               name="keyword"
                                               className="form-control mr-2"
                                               style={{width: '230px'}}
                                               placeholder="ค้น ทะเบียนรถ, ชื่อลูกค้า"
                                               onChange={(e) => {
                                                   let val = e.target.value
                                                   this.setState({
                                                       keyword: val
                                                   })
                                               }}
                                        />
                                        <div className="form-group">
                                            <button type="button" className="btn btn-primary"
                                                    onClick={() => {
                                                        this.loadCarBookOutstanding()
                                                    }}
                                            >ค้นหา
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="row col-12">
                                    <div className="form-group row">
                                        <div className="col-md-12">

                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <table className="table table-bordered table-striped table-md">
                                            <thead>
                                            <tr>
                                                <th className="text-center" width="16%">วันที่</th>
                                                <th className="text-center" width="20%">ผู้จอง</th>
                                                <th className="text-center" width="15%">รายการ</th>
                                                <th className="text-center" width="15%">ยอดเงินที่ชำระ</th>
                                                <th className="text-center" width="17%">ประเภทการชำระ</th>
                                                <th className="text-center" width="18%">ธนาคาร</th>
                                                <th className="text-center text-nowrap" width="21%">ผู้บันทึกรายการ</th>
                                                <th className="text-center" width="13%">จัดการ</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {car_sell_pay_tr}
                                            <tr
                                                className={(this.state.car_book_list.length === 0) ? 'text-center' : ' hidden'}>
                                                <td colSpan="8">
                                                    <span className="text-danger"> ไม่พบข้อมูลการจอง </span>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


const styles = {
    bottomLine: {
        borderBottomColor: '#ccc',
        borderBottomStyle: 'solid',
        borderWidth: 0.5,
    },
    fileInputWrapper: {
        position: 'relative',
        overflow: 'hidden',
        height: 180,
        width: 200,
    },
    btnFileInput: {
        height: 180,
        width: 200,
        display: 'inline-block',
        backgroundColor: "#D2DDE2",
        backgroundImage: "linear-gradient(to bottom, #D2DDE2, #FCF8FF)",
        backgroundRepeat: "repeat-x",
        color: "#ffffff",
        textShadow: "0 -1px 0 rgba(0, 0, 0, 0.25)"
    },
    btnFileInputImage:{
        position: 'absolute',
        top: 0,
        left: 0,
        height: 180,
        width: 200,
    },
    fileInput: {
        position: 'absolute',
        top: 0,
        right: 0,
        opacity: 0,
        height: 180,
        width: 200,
        cursor: 'pointer'
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    type: state.auth.type,
    token: state.auth.token,
    account_branch_id: state.auth.account_branch_id,
    isAuthenticated: state.auth.isAuthenticated
})
export default connect(mapStateToProps)(CarReservationOutstanding)
